import React from "react";
import { useModal } from "../../context/modalContext";
import { FiX } from "react-icons/fi";

const StreamModal = ({ children }) => {
  const { isOpen, closeModal } = useModal();

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-content-wrapper">
          {children}
          <div onClick={closeModal} className="closeBtn">
            <FiX className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamModal;
