import React, { useState, useEffect } from "react";
import styles from "./Sign.module.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import Axios from "axios";
import Config from "../../Config.json";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import GoToTop from "../GoToTop";
import Swal from "sweetalert2";
import SocialAuth from "./SocialAuth";
import axios from "axios";

const SignUp = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [seePass, setSeePass] = useState(false);
  const [seeConfirmPass, setSeeConfirmPass] = useState(false);
  //const token = sessionStorage.getItem("token");

  const onSubmit = async (data) => {
    // Check if there are no validation errors
    if (errors.length > 0) return;
    setErrorMsg("");
    setBtnDisabled(true);
    // Check if passwords match
    if (data.password !== data.confirmPassword) {
      setErrorMsg("Passwords do not match.");
      setBtnDisabled(false);
      return;
    }
    // Ensure Terms and Conditions are accepted
    if (!accept) {
      Swal.fire({
        icon: "error",
        position: "top-right",
        text: "You must accept our Terms and Conditions to proceed.",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      setBtnDisabled(false);
      return;
    }
    const userDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
    };
    try {
      // API call to signup the user
      const response = await axios.post(
        `${Config.SERVER_URL}/auth/signup`,
        userDetails
      );
      if (response?.data?.status === "success") {
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            position: "top-right",
            text: "Account created successfully",
            toast: true,
            timer: 3000,
            showConfirmButton: false,
          });
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        position: "top-right",
        text: `${error?.response?.data?.error}`,
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      console.error("Error during signup:", error?.response?.data?.error);
    } finally {
      setBtnDisabled(false);
    }
  };

  return (
    <>
      <section className={styles.loginContainer}>
        <div className={styles.co5}></div>

        <div className={styles.co3}>
          <Link to="/" className={[styles.imgDiv]}>
            <img
              src="images/logo.png"
              width="80"
              className="d-inline-block align-top"
              alt=""
            />
          </Link>
          <div className="loginBack mt-4">
            <h3 className={styles.welText}>
              Let's get,
              <br /> you started!
            </h3>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMsg && (
              <span className={styles.floatingAlert}>
                <strong>{errorMsg}</strong>
              </span>
            )}

            <div>
              {errors.firstName && (
                <span className={styles.formAlert}>
                  * First Name is required
                </span>
              )}
              <label className={styles.field}>
                <input
                  className={styles.field__area}
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  {...register("firstName", { required: true })}
                />
                <div className={styles.field__label}> First Name</div>
              </label>
            </div>

            <div>
              {errors.lastName && (
                <span className={styles.formAlert}>
                  * Last Name is required
                </span>
              )}
              <label className={styles.field}>
                <input
                  className={styles.field__area}
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  {...register("lastName", { required: true })}
                />
                <div className={styles.field__label}>Last name</div>
              </label>
            </div>

            <div>
              {errors.email && (
                <span className={styles.formAlert}>* Email is required</span>
              )}
              <label className={styles.field}>
                <input
                  className={styles.field__area}
                  type="email"
                  name="email"
                  placeholder="Write your email"
                  {...register("email", { required: true })}
                />
                <div className={styles.field__label}>Email</div>
              </label>
            </div>

            <div>
              {errors.phoneNumber && (
                <span className={styles.formAlert}>
                  * Phone Number is required
                </span>
              )}
              <label className={styles.field}>
                <input
                  className={styles.field__area}
                  type="tel"
                  name="phoneNumber"
                  placeholder="Write your Phone Number"
                  {...register("phoneNumber", { required: true })}
                />
                <div className={styles.field__label}>Phone Number</div>
              </label>
            </div>

            <div>
              {errors.password && (
                <span className={styles.formAlert}>* Password is required</span>
              )}
              <label className={styles.field}>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.field__area}
                    type={seePass ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    {...register("password", { required: true, min: 8 })}
                  />
                  <span
                    onClick={() => setSeePass(!seePass)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      marginRight: "20px",
                    }}
                  >
                    {seePass ? (
                      <BsEyeFill style={{ fontSize: "18px", color: "#fff" }} />
                    ) : (
                      <BsEyeSlashFill
                        style={{ fontSize: "18px", color: "#fff" }}
                      />
                    )}
                  </span>
                </div>

                <div className={styles.field__label}> Password</div>
              </label>
            </div>

            <div>
              {errors.confirmPassword && (
                <span className={styles.formAlert}>* Re-type Password</span>
              )}
              <label className={styles.field}>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.field__area}
                    type={seeConfirmPass ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    {...register("confirmPassword", { required: true })}
                  />
                  <span
                    onClick={() => setSeeConfirmPass(!seeConfirmPass)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      marginRight: "20px",
                    }}
                  >
                    {seeConfirmPass ? (
                      <BsEyeFill style={{ fontSize: "18px", color: "#fff" }} />
                    ) : (
                      <BsEyeSlashFill
                        style={{ fontSize: "18px", color: "#fff" }}
                      />
                    )}
                  </span>
                </div>

                <div className={styles.field__label}>Confirm Password</div>
              </label>
            </div>

            <div
              className="form-group mt-4 text-white"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label className={`${styles.remberText}`} style={{}}>
                <input type="checkbox" className={styles.checkbox} /> Remember
                me.
              </label>

              <label className={`${styles.remberText}`}>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  onClick={() => {
                    setAccept(!accept);
                  }}
                />
                <span>
                  I agree to the{" "}
                  <span className="terms"> Terms and Privacy Policy</span>
                </span>
              </label>
            </div>

            {btnDisabled !== true ? (
              <button
                type="submit"
                className={`${styles.btnOutlined} ${"btn"}`}
              >
                Sign Up
              </button>
            ) : (
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                visible={true}
              />
            )}
          </form>

          <div className="social__div">
            <p className="text-center text-white">- Or Continue using -</p>

            <SocialAuth authType="signup" />

            <p className="text-center text-white mt-5">
              Already have an account{" "}
              <Link style={{ color: "#f8d7da" }} to="/LogIn">
                Login
              </Link>
            </p>
          </div>
        </div>

        <div className={styles.co5}></div>
      </section>

      <GoToTop />
    </>
  );
};

export default SignUp;
