import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { useEvent } from "../../../context/EventContext";

const EventCard = (props) => {
  const { setCurrentEventData, publishEvent, disableBtn } = useEvent();
  const navigate = useNavigate();
  const { data } = props;
  const eventDetails = (data) => {
    // sessionStorage.setItem("eventId", data.eventId);
    navigate(`/user/Details/${data?.title}/${data._id}`, {
      state: { data: data },
    });
  };

  return (
    <>
      {data && data?.length > 0 ? (
        data?.map((data) => {
          return (
            <div
              key={data?._id}
              className="col-lg-4 col-md-6"
              onClick={data?.isPublished ? () => eventDetails(data) : undefined}
            >
              <div className="Cardy card mt-3 ">
                <img
                  src={data?.displayImage}
                  className="card-img-top mh-50 "
                  alt="..."
                />
                <div className="card-body p-4">
                  <h5>{data?.title}</h5>
                  <p>{data?.description}</p>
                  {!data?.isPublished && (
                    <div className="BadagryDate gap-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <button
                          className="btn btn-primary btn-elrio"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                          onClick={() => publishEvent(data)}
                          disabled={disableBtn}
                        >
                          <FiCheck />
                          <span>Publish Event</span>
                        </button>
                        <button
                          className="btn btn-primary btn-elrio"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                          disabled={disableBtn}
                          onClick={() => setCurrentEventData(data)}
                        >
                          <FiEdit2 />
                          <span>Edit Event</span>
                        </button>
                      </div>
                    </div>
                  )}
                  {data?.isPublished && (
                    <div className="BadagryDate gap-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="datTime">
                          <i className="fas fa-calendar-alt"></i>{" "}
                          {format(new Date(data?.startDate), "dd MMMM yy")}
                        </span>
                        <span className="datTime">
                          <i className="fas fa-clock"></i> {data?.startTime}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            marginTop: "10%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <img
            src="/images/notFound/savedEvnt.png"
            className={`${"img-fluid"} ${"notFound"}`}
            alt="no found"
          />
          <h4 style={{ fontSize: "20px", marginTop: "5%", fontWeight: "bold" }}>
            No Event Found
          </h4>
        </div>
      )}
    </>
  );
};

export default EventCard;
