import React from "react";
import styles from "../Style.module.css";
import {
  FaFacebook,
  FaGooglePlus,
  FaTwitterSquare,
  FaArrowRight,
  FaInstagramSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className={`${styles.footerSection} ${"text-white"}`}>
        <div className={`${styles.secFooter} ${"text-center text-white py-4"}`}>
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className={styles.copywrite}>
                <a
                  className="btn btn-floating  text-white"
                  href="https://feroevent.com/"
                >
                  {" "}
                  © 2022 All rights reserved feroevent.com
                </a>
              </span>
            </div>
          </div>
        </div>

        <section className={styles.lastFooter}>
          <div className={styles.footerBoxOne}>
            <img
              src="images/logo.png"
              width="100"
              className="d-inline-block align-top"
              alt=""
            />

            <ul className={styles.footerNavList}>
              {/* <li className={styles.list}>Watch Demo</li> */}
              <li className={styles.list}>
                <Link to="/Pricing" style={{ color: "#fff" }}>
                  Pricing
                </Link>
              </li>
              <li className={styles.list}>
                <Link to="/FAQs" style={{ color: "#fff" }}>
                  FAQs
                </Link>
              </li>
              <li className={styles.list}>
                <Link to="/" style={{ color: "#fff" }}>
                  Privacy Policy
                </Link>
              </li>
              <li className={styles.list}>
                <Link to="/" style={{ color: "#fff" }}>
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.storeFooter}>
            <div className={styles.downloadText}>
              <p>
                Create your live events, seminars, or workshops, from any part
                of the world by broadening your reach.
              </p>
              <div className={styles.midFooter}>
                <a
                  className={`${
                    styles.iconFoot
                  } ${" btn-link btn-floating btn-md text-white"}`}
                  href="https://www.facebook.com/"
                  role="button"
                >
                  <FaFacebook />
                </a>
                <a
                  className={`${
                    styles.iconFoot
                  } ${" btn-link btn-floating btn-md text-white"}`}
                  href="mailto:@gmail.com"
                  role="button"
                >
                  <FaGooglePlus />
                </a>

                <a
                  className={`${
                    styles.iconFoot
                  } ${" btn-link btn-floating btn-md text-white"}`}
                  href="https://www.instagram.com/"
                  role="button"
                >
                  <FaInstagramSquare />
                </a>

                <a
                  className={`${
                    styles.iconFoot
                  } ${" btn-link btn-floating btn-md text-white"}`}
                  href="https://www.twitter.com/"
                  role="button"
                >
                  <FaTwitterSquare />
                </a>
              </div>
            </div>
            <div className={styles.downloadA}>
              <img
                src="./images/google.png"
                className={`${styles.iosFootIcon} ${"d-block img-fluid"}`}
                alt="playstore"
              />
              <img
                src="./images/ios-footer.png"
                className={`${styles.iosFootIcon} ${"d-block img-fluid"}`}
                alt="ios"
              />
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
