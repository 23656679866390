import React, { useState, useEffect } from "react";
import Config from "../../../../Config.json";
import { format } from "date-fns";
import { ThreeDots } from "react-loader-spinner";

const Reviews = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [reviews, setReviews] = useState("");

  const { id } = props;

  const _id = sessionStorage.getItem("id");

  console.log(id);

  const myReviews = async () => {
    return fetch(`${Config.SERVER_URL}/events/${id}/reviews`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("this is the review data", responseJson);
        if (responseJson.status === "success") {
          setReviews(responseJson.data.eventReviews);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    myReviews();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#003399"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              visible={true}
            />
          </div>
        </>
      ) : (
        <>
          {reviews && reviews.length > 0 ? (
            reviews.map((review, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="chat"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div className="imgBox">
                      {/* <img src='/images/prof.png' className='img-fluid d-block'/> */}
                    </div>
                    <div className="textBox">
                      <div className="heading">
                        <h5>{review?.title}</h5>
                        <h6>
                          {format(new Date(review.createdAt), "yyyy-MM-dd")}
                          <br />
                          {format(new Date(review.createdAt), "HH :mm")} minute
                        </h6>
                      </div>
                      <p>{review.comment}</p>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <div className="chat">No Review yet</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Reviews;
