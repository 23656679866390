import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Config from "../../Config.json";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GoogleIcon from "../../Images/google.svg";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { ThreeDots } from "react-loader-spinner";

const SocialAuth = ({ authType }) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const signup = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const sessionConfiguration = useCallback(
    (response) => {
      sessionStorage.clear();
      sessionStorage.setItem("token", response?.data?.data?.token);
      sessionStorage.setItem(
        "userDetails",
        JSON.stringify(response?.data?.data)
      );
      sessionStorage.setItem(
        "firstName",
        `${response?.data?.data?.user?.firstName}`
      );
      sessionStorage.setItem(
        "lastName",
        `${response?.data?.data?.user?.lastName}`
      );
      sessionStorage.setItem("userId", `${response?.data?.data?.user?._id}`);
      navigate("/user/Events");
    },
    [navigate]
  );

  const SignupUser = useCallback(
    async (userInfo) => {
      const userDetails = {
        firstName: userInfo?.given_name,
        lastName: userInfo?.family_name,
        email: userInfo?.email,
        password: "",
        phoneNumber: ``,
      };

      try {
        setLoading(true);
        const response = await axios.post(
          `${Config.SERVER_URL}/auth/signup`,
          userDetails
        );
        sessionConfiguration(response);
        if (response) {
          Swal.fire({
            icon: "success",
            position: "top-right",
            text: "Account created successfully",
            toast: true,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          position: "top-right",
          text: `${
            error?.response?.data?.error || "Error Occured, please try again"
          }`,
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [sessionConfiguration]
  );

  const LoginUser = useCallback(
    async (userInfo) => {
      try {
        setLoading(true);
        const details = {
          email: userInfo?.email,
          password: "",
          isGoogle: true,
        };
        const response = await axios.post(
          `${Config.SERVER_URL}/auth/login`,
          details
        );
        sessionConfiguration(response);
        if (response) {
          Swal.fire({
            icon: "success",
            position: "top-right",
            text: "Login successful",
            toast: true,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          position: "top-right",
          text: `${
            error?.response?.data?.error || "Error Occured, please try again"
          }`,
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [sessionConfiguration]
  );

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const userInfo = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: "application/json",
              },
            }
          );
          if (authType === "signin") {
            LoginUser(userInfo?.data);
          } else {
            SignupUser(userInfo?.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [user, SignupUser, LoginUser, authType]);

  const logOut = () => {
    googleLogout();
    setUser(null);
  };

  const handleAuth = () => {
    if (authType === "signin") {
      signup();
    } else {
      signup();
    }
  };

  useEffect(() => {
    logOut();
    sessionStorage.clear();
  }, []);

  return (
    <>
      <div className="auth__container">
        <div className={loading ? "auth__wrapper loadingBtn" : "auth__wrapper"}>
          {loading ? (
            <ThreeDots
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperClass="threeDots"
              visible={true}
            />
          ) : (
            <div
              onClick={() => handleAuth(`${authType}`)}
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <span>
                <img src={GoogleIcon} alt="google logo" />
              </span>
              <span>
                <h1>Continue with Google</h1>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SocialAuth;
