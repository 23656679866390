import React, { useState } from "react";
import Config from "../../../../Config.json";
import Swal from "sweetalert2";

const CreatBaord = (props) => {
  const { data } = props;

  const [inputGroups, setInputGroups] = useState([
    { name: "", type: "note", content: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const token = sessionStorage.getItem("token");

  const handleTypeChange = (index, value) => {
    const newInputGroups = [...inputGroups];
    newInputGroups[index].type = value;
    setInputGroups(newInputGroups);
  };

  //   const handleFileChange = (event, type) => {
  //     const file = event.target.files[0];
  //     const newInputs = [...inputGroups];
  //     if (type === 'image') {
  //       setImgfile(URL.createObjectURL(file));
  //       newInputs[0].content = file;
  //     } else {
  //       setSelectedTypeContent(file);
  //       newInputs[0].content = file;
  //     }
  //     setInputGroups(newInputs);
  //   };

  const handleInputChange = (index, field, value) => {
    const newInputGroups = [...inputGroups];
    newInputGroups[index][field] = value;
    setInputGroups(newInputGroups);
  };

  const addInputGroup = () => {
    setInputGroups([{ name: "", type: "note", content: "" }]);
  };

  const removeInputGroup = (index) => {
    const newInputGroups = inputGroups.filter((_, i) => i !== index);
    setInputGroups(newInputGroups);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.target;
    const formData = new FormData(form);

    inputGroups.forEach((group, index) => {
      formData.append(`boards[${index}][name]`, group.name);
      formData.append(`boards[${index}][type]`, group.type);
      if (group.type === "note") {
        formData.append(`boards[${index}][content]`, group.content);
      } else {
        formData.append(`content${index}`, group.content);
      }
    });
    fetch(`${Config.SERVER_URL}/events/${data?._id}/boards`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: formData,
    })
      .then(async (response) => {
        const text = await response.text();
        if (response.ok) {
          try {
            JSON.parse(text);
            Swal.fire({
              title: "",
              text: "New board added successfully.",
              icon: "success",
              confirmButtonText: "Ok",
              showCancelButton: false,
            });
            window.location.reload();
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error("Error parsing JSON:", error);
          }
        } else {
          setLoading(false);
          console.error("Error:", text);
        }
      })
      .catch((error) => console.error("Fetch error:", error));
  };

  return (
    <div className="mt-2">
      <form id="dynamicForm" onSubmit={handleSubmit}>
        <div id="inputGroupContainer">
          {inputGroups?.map((group, index) => (
            <div key={index} className="input-group mb-3">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  rowGap: "5px",
                }}
              >
                <div>
                  <label
                    htmlFor="name"
                    style={{ fontSize: "14px", color: "rgba(0,0,0,0.8)" }}
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    value={group.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="type"
                    style={{ fontSize: "14px", color: "rgba(0,0,0,0.8)" }}
                  >
                    Media Type
                  </label>
                  <select
                    className="form-select"
                    name="type"
                    value={group.type}
                    onChange={(e) => handleTypeChange(index, e.target.value)}
                    required
                  >
                    <option value="note">Note</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="document">Document</option>
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <input
                  type={group.type !== "note" ? "file" : "text"}
                  className="form-control mt-2"
                  name="content"
                  placeholder="Content"
                  required
                  value={group.content}
                  onChange={(e) =>
                    handleInputChange(index, "content", e.target.value)
                  }
                />
                {inputGroups.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => removeInputGroup(index)}
                    disabled={loading}
                  >
                    -
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addInputGroup}
            disabled={loading}
          >
            reset
          </button>
          <button type="submit" className="btn btn-success" disabled={loading}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatBaord;
