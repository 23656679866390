import React, { useState, useEffect } from "react";
import Config from "../../../../Config.json";
import { ThreeDots } from "react-loader-spinner";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";

const EventSide = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const myEvents = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${Config.SERVER_URL}/events/recommended/10`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        setEvents(data?.data?.events);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    myEvents();
  }, []);

  return (
    <>
      <div
        className=""
        style={{
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: "0px 10px",
        }}
      >
        {loading === true ? (
          <>
            <div>
              <ThreeDots
                height="40"
                width="40"
                radius="10"
                color="#003399"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                visible={true}
              />
            </div>
          </>
        ) : (
          <>
            {events?.map((data) => {
              return (
                <Link to={`/Event/${data?._id}`} key={data?._id}>
                  <div className="cardTwo" style={{ cursor: "pointer" }}>
                    <div className="imgCardBox">
                      <img
                        src={data?.displayImage}
                        style={{
                          display: "flex",
                          width: "100px",
                          height: "150px",
                          borderRadius: "8px",
                          objectFit: "contain",
                        }}
                        alt="..."
                      />
                    </div>
                    <div className="eventDetails">
                      <div className="textDetails">
                        <h5>{data?.title}</h5>
                        <p>{data?.description.substring(0, 50)}</p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            fontSize: "14px",
                            position: "absolute",
                            bottom: "10px",
                            left: "0px",
                          }}
                        >
                          <i className="fa-solid fa-calendar-days"></i>
                          <div>
                            {" "}
                            {format(new Date(data?.startDate), "dd MMMM yy")}
                          </div>
                        </div>
                        {data?.isLive && (
                          <p>
                            <i className="fa-solid fa-eye"></i>{" "}
                            {data?.reviewsCount} Views
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
            <div style={{ marginBottom: "10px" }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default EventSide;
