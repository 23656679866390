import React, { useState, useEffect } from "react";
import styles from "./Sign.module.css"
import { useForm } from "react-hook-form";
import Axios from "axios";
import Config from "../../Config.json"
import { ThreeDots } from 'react-loader-spinner'
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import GoToTop from "../GoToTop";
import { Link, useNavigate, useLocation } from "react-router-dom";

const OtpScreen = () => {
    const {state} = useLocation();
    const { data } = state;

    const email = data;
    console.log(email);

    let navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [otp, setotp] = useState(false);


    const onSubmit = (datas) => {
    //    console.log(datas.token);
    //    return
        setErrorMsg('');
        let details = {
            email: email,
            token: datas.token
        };
        setBtnDisabled(true);
        setTimeout(async() => {

            return Axios.post(`${Config.SERVER_URL}/auth/verify-email/`, details)
                .then(async (res) => {
                    console.log(res);
                    // return
                    if (res.data.success) {
                        sessionStorage.clear();
                        sessionStorage.setItem("token", res.data.token);
                        sessionStorage.setItem("userDetails", JSON.stringify(res.data.user));
                        sessionStorage.setItem("firstName", `${res.data.user.firstName}`)
                        sessionStorage.setItem("lastName", `${res.data.user.lastName}`)
                        sessionStorage.setItem("userId", `${res.data.user._id}`)
                        navigate("/Preference");
                    }
                    setBtnDisabled(false);
                })
                .catch(err => {
                    console.log(err);
                    setErrorMsg(err.message);
                    setBtnDisabled(false);
                })

        }, 2000);
    };



    return (

        <>


            <section className={styles.loginContainer}>

                <div className={styles.co5}></div>


                <div className={styles.co3}>

                    <Link to="/" className={styles.imgDiv}>
                        <img src="images/logo.png" width="80" className="d-inline-block align-top" alt="" />
                    </Link>

                    <div className="loginBack mt-4">
                        <h3 className={styles.welText}>Verify Email</h3>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {errorMsg &&
                            <span className={styles.formAlert}>
                                <strong>{errorMsg}</strong>
                            </span>
                        }
                        <div>
                            {errors.password && <span className={styles.formAlert}>* Token is required</span>}
                            <label className={styles.field}>
                                <div style={{ display: "flex" }}>
                                    <input className={styles.field__area} type={otp ? "text" : "password"} name="token" placeholder="Token"  {...register("token", { required: true })} />
                                    <span onClick={() => setotp(!otp)} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", marginRight: "20px" }}>
                                        {otp ? <BsEyeFill style={{ fontSize: "18px", color: "#fff" }} /> : <BsEyeSlashFill style={{ fontSize: "18px", color: "#fff" }} />}

                                    </span>

                                </div>
                                <div className={styles.field__label}>
                                    <span> Token </span>
                                </div>
                            </label>
                        </div>




                        {btnDisabled !== true ?
                            <button type="submit" className={`${styles.btnOutlined} ${"btn"}`}>
                                Submit
                            </button>
                            :
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#fff"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                visible={true} />
                        }


                    </form>



                </div>


                <div className={styles.co5}></div>





            </section>




            <GoToTop />


        </>

    );
};

export default OtpScreen;
