import React, { useEffect, useState } from "react";
import Config from "../../../../Config.json";
import { ThreeDots } from "react-loader-spinner";

const Participants = (props) => {
  const { data } = props;
  const [participant, setParticipant] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profilePhotoUrl, setprofilePhotoUrl] = useState("");
  const [name, setName] = useState("");

  // const _id = sessionStorage.getItem("id");
  // console.log(data._id)

  const getParticipant = async () => {
    return fetch(`${Config.SERVER_URL}/events/${data._id}/attenders`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Resp ", responseJson);
        if (responseJson.status === "success") {
          setParticipant(responseJson?.data?.attenders);
          // setprofilePhotoUrl(responseJson.data.events.userId.profilePhotoUrl);
          // setName(responseJson.data.events.userId.firstName + " " + responseJson.data.events.userId.lastName);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getParticipant();
  }, []);

  return (
    <div>
      {participant && participant.length > 0 ? (
        participant.map((p, index) => {
          return (
            <>
              <div key={index} className="part-wrap">
                <img
                  src={p?.user?.profilePhotoUrl ?? "../../../images/user.png"}
                  className="img-fluid d-block part-img"
                  alt="..."
                />
                <p className="part-name">{p?.user?.name}</p>
              </div>
            </>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="text-center"
        >
          <img
            src={"../../../../images/dataNotFound.png"}
            className="profileImg mb-0"
            alt="..."
          />
          <div className="chat">No Particpant Found</div>
        </div>
      )}
    </div>
  );
};

export default Participants;
