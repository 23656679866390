import React, { useEffect } from "react";
import WOW from "wowjs";
import { Link } from "react-router-dom";
import styles from "./Nav.module.css";
import { HiMenuAlt3 } from "react-icons/hi";

const Nav = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <div className={styles.contain}>
        <nav
          className="navbar navbar-expand-md navbar-light wow fadeInDown"
          data-wow-duration="1s"
          id="navbarNav1"
        >
          <div className="container">
            <Link to={"/"} className="navbar-brand" id="logo-m">
              <img
                src="images/LogoTwo.png"
                width="80"
                className="d-inline-block align-top"
                alt=""
              />
            </Link>

            <button
              className={`${styles.navbarTog} ${"navbar-toggler"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <HiMenuAlt3 />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* <ul className="navbar-nav  mx-auto nm-r">
                <li className="nav-item">
                  <Link
                    to="/About-us"
                    className={`${
                      styles.navlink
                    } ${"nav-link waves-effect waves-light active-nav"}`}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/Solutions"
                    className={`${
                      styles.navlink
                    } ${"nav-link waves-effect waves-light active-nav"}`}
                  >
                    Solutions
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/FAQs"
                    className={`${
                      styles.navlink
                    } ${"nav-link waves-effect waves-light active-nav"}`}
                  >
                    FAQs
                  </Link>
                </li>
              </ul> */}

              {/* <ul className="navbar-nav  ms-auto">
                <li className="nav-item">
                  <Link
                    to="/LogIn"
                    className={`${
                      styles.btnRoundedOutline
                    } ${"nav-link btn btn-primary waves-effect waves-light"}`}
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/SignUp"
                    className={`${
                      styles.btnRounded
                    } ${"nav-link btn btn-primary waves-effect waves-light"}`}
                  >
                    Sign up
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </nav>
        {/* <div className="bottom-nav"></div> */}
      </div>
    </>
  );
};

export default Nav;
