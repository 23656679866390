import Swal from "sweetalert2";
import Config from "../Config.json";
import { Axios } from "./AxiosConfig";

function convertISOToMMDDYYYY(isoDate) {
  const date = new Date(isoDate);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

const handleDeleteBtn = (url, redirectTo) => {
  Swal.fire({
    title: "Please confirm action.",
    text: "Do you want to delete this item?",
    showCancelButton: true,
    confirmButtonText: "Delete",
    showLoaderOnConfirm: true,
    confirmButtonColor: "#d33",
    preConfirm: async () => {
      try {
        const response = Axios.delete(`${Config.SERVER_URL}/${url}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        });
        if (!response) {
          Swal.showValidationMessage("Something went wrong please try again");
        }
      } catch (error) {
        Swal.showValidationMessage(`
            Request failed: ${error}
          `);
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "",
        text: "Item deleted successfully.",
        icon: "success",
        confirmButtonText: "Ok",
        showCancelButton: false,
      }).then((status) => {
        if (status.isConfirmed) {
          redirectTo
            ? (window.location = `${redirectTo}`)
            : window.location.reload(true);
        }
      });
    }
  });
};

export { convertISOToMMDDYYYY, handleDeleteBtn };
