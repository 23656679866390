import React, {useState, useEffect} from 'react';
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'
import Swal from 'sweetalert2';

const MyInterest = () => {


  const [interest, setinterest] = useState([]);
  const [upInterest, setUpInterest] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [mycateg, setMyCateg] = useState([]);
  const [preference, setPreference] = useState([]);
  
  const selectFeed = (item) => {
    if(mycateg.includes(item)){
      mycateg.splice(mycateg.indexOf(item), 1);
      console.log(mycateg);
      return;
    }
    mycateg.push(item);
    console.log(mycateg);
  }

  const getinterest = async() => {

    const userId = sessionStorage.getItem("userId");
      setPageLoading(true);

      return fetch(`${Config.SERVER_URL}/categories`, {
          method:"get",
          headers:{
              Accept: "application/json",
              "Content-Type": "application/json",
              "token": sessionStorage.getItem("token")  
          }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        console.log("all interest", responseJson)

          if(responseJson.status === "success"){
              setinterest(responseJson.data.categories);
              setPageLoading(false);
          }

      })
      .catch((error) => {
        console.error(error);
        setPageLoading(false);
      });




  }

  const updateInterest = async()=>{

    if(mycateg.length < 1) return

    // const interest = [
    //   mycateg
    // ]
    const interests = mycateg.map(id => ({ id }));

    //   console.log(interests)
    // return

     
    return fetch(`${Config.SERVER_URL}/categories/interests/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token":  sessionStorage.getItem("token")
      },
        body: JSON.stringify({
           interests:interests,
        })
    })
      .then((response) => response.json())
      .then((responseJson) => {
          console.log(responseJson);
          getinterest()
          Swal.fire({
            icon:'success',
            text: 'Intereast successfully updated',
            toast:true,
            timer: 3000,
            showConfirmButton:false,
            timerProgressBar: true,
            position:"top-right"
          })
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getMyCateg = async()=> {
    setPageLoading(true);
    return fetch(`${Config.SERVER_URL}/auth/reload-user`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")  
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log("resp interest", responseJson.data.user)
        setMyCateg(responseJson.data.user.interests);
        setPageLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setPageLoading(false);
    });
  }

  const addCategory = (id) => {
    setMyCateg((prevMycateg) => {
      if (prevMycateg.includes(id)) {
        // Remove the id if it already exists
        return prevMycateg.filter((item) => item !== id);
      } else {
        // Add the id if it doesn't exist
        return [...prevMycateg, id];
      }
    });
  };

  console.log(mycateg)


  useEffect(()=>{
      getinterest();
      getMyCateg();
  },[])

    return (
        
      <>
        <div className="interestBody">
             <div className="d-flex justify-content-between">
                <p>My Interest</p>
                <span onClick={updateInterest} style={{cursor:'pointer'}} className="">Update Interest</span>
             </div>

            <div className="interestFlex">

        {pageLoading === true ?
                <>
                  <div style={{height:"100%", display:"flex", justifyContent:"center", width:"100%", alignItems:'center'}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>

              <span className="" style={{display:"flex", justifyContent:"flex-end", width:"100%", alignItems:'flex-end'}}/>

              {interest && interest.length > 0
                    ? interest.map((c, index) => {
                      const isCategoryIncluded = mycateg.includes(c._id);
                      const textColorClass = isCategoryIncluded ? "interestBox-title" : "interestBox-titleInactive";
                      const bgColor = isCategoryIncluded ? "activeInterestBox" : "inactiveInterestBox";
                      return <>
              
                <div className={`${bgColor}`} onClick={() => addCategory(c._id)}>
                    <img src="../images/logo.png" className={[((mycateg).includes(c._id)) ? "interest-img img-fluid" : "img-fluid"]}/>
                    <span className={`${textColorClass}`}>{c?.name}</span>
                </div>

                </> })
                : <div className='noInt' style={{height:"100%", display:"flex", justifyContent:"center", width:"100%", alignItems:'center', backgroundColor:"#f9f9f9"}}>
                              <h4 style={{marginTop:"20px", fontSize:"16px", fontWeight:'normal'}}>No interest Found</h4>
                </div>}

                    <span className="" style={{display:"flex", justifyContent:"flex-end", width:"100%", alignItems:'flex-end'}}/>

                    </>
                                }
            </div>

          </div>

            
        </>
    );
};

export default MyInterest;