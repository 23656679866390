import React from "react";
import Sidebar from "./components/Sidebar";
import { useModal } from "../../context/modalContext";

const Layout = ({ children }) => {
  const { cameraSetting } = useModal();
  return (
    <div className="contentParent" style={{ position: "relative" }}>
      <Sidebar />
      <div className={`${cameraSetting ? "videoPreview" : "content"}`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
