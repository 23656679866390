import React, { useState } from "react";
import Config from "../../../../Config.json";
import Swal from "sweetalert2";
import { useBoardModal } from "../../../../context/BoardContext";
import { Axios } from "../../../../Utils/AxiosConfig";

const UpdateBoard = () => {
  const { boardData } = useBoardModal();

  const [inputGroups, setInputGroups] = useState([
    { name: "", type: "note", content: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const token = sessionStorage.getItem("token");

  const handleTypeChange = (index, value) => {
    const newInputGroups = [...inputGroups];
    newInputGroups[index].type = value;
    setInputGroups(newInputGroups);
  };

  const handleInputChange = (index, field, value) => {
    const newInputGroups = [...inputGroups];
    newInputGroups[index][field] = value;
    setInputGroups(newInputGroups);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.target;
    const formData = new FormData(form);

    inputGroups.forEach((group, index) => {
      formData.append(`boards[${index}][name]`, group.name);
      formData.append(`boards[${index}][type]`, group.type);
      if (group.type === "note") {
        formData.append(`boards[${index}][content]`, group.content);
      } else {
        formData.append(`content${index}`, group.content);
      }
    });
    try {
      await Axios.put(
        `${Config.SERVER_URL}/events/${boardData?.eventId}/boards/${boardData?._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-access-token": token,
          },
        }
      );
      Swal.fire({
        timer: 3000,
        text: "Board updated successflly.",
        toast: true,
        position: "top-right",
        icon: "error",
        showConfirmButton: false,
        timerProgressBar: true,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      Swal.fire({
        timer: 3000,
        text: `${error?.response?.data?.message}`,
        toast: true,
        position: "top-right",
        icon: "error",
        showConfirmButton: false,
        timerProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-2">
      <form id="dynamicForm" onSubmit={handleSubmit}>
        <div id="inputGroupContainer">
          <div key={boardData?._id} className="input-group mb-3">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                rowGap: "5px",
              }}
            >
              <div>
                <label
                  htmlFor="name"
                  style={{ fontSize: "14px", color: "rgba(0,0,0,0.8)" }}
                >
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  defaultValue={boardData?.name}
                  onChange={(e) => handleInputChange(0, "name", e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="type"
                  style={{ fontSize: "14px", color: "rgba(0,0,0,0.8)" }}
                >
                  Media Type
                </label>
                <select
                  className="form-select"
                  name="type"
                  defaultValue={boardData?.type}
                  onChange={(e) => handleTypeChange(0, e.target.value)}
                  required
                >
                  <option value="note">Note</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="document">Document</option>
                </select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <input
                type={inputGroups[0]?.type !== "note" ? "file" : "text"}
                className="form-control mt-2"
                name="content"
                placeholder="Content"
                required
                defaultValue={boardData?.content}
                onChange={(e) =>
                  handleInputChange(0, "content", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button type="submit" className="btn btn-success" disabled={loading}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateBoard;
