import { Offcanvas, Row, Col } from "react-bootstrap";
import {
  BsEyeFill,
  BsEyeSlashFill,
  BsFillCloudUploadFill,
} from "react-icons/bs";
import { ThreeDots } from "react-loader-spinner";
import { useEvent } from "../../context/EventContext";
import { convertISOToMMDDYYYY } from "../../Utils/utils";

const EventOffcanvas = () => {
  const {
    showOffcanvas,
    offCanvasClose,
    title,
    setTitle,
    displayImage,
    setDisplayImage,
    desc,
    setDesc,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    categoryId,
    setCategoryId,
    selectedCat,
    cat,
    isPassword,
    setPasswordState,
    password,
    setPassword,
    msg,
    loading,
    handleBgUpload,
    publishEvent,
    editEvent,
    currentEvent,
    disableBtn,
    today,
    saveEvent,
  } = useEvent();

  return (
    <Offcanvas show={showOffcanvas} onHide={offCanvasClose} placement={"end"}>
      <Offcanvas.Header style={{ padding: "2% 10%" }} closeButton>
        <Offcanvas.Title>
          {currentEvent
            ? `Update Event ${currentEvent?.title}`
            : "create new Event"}{" "}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading === true && (
          <>
            <div className="loading">
              <ThreeDots
                height="40"
                width="40"
                radius="10"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                visible={true}
              />
            </div>
          </>
        )}

        <Row>
          <Col lg="1" />
          <Col lg="10" style={{ paddingBottom: "120px" }}>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <div
                className={displayImage === "" ? "d-none" : "eventbg_image"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  document.getElementById("customFile").click();
                }}
              >
                {displayImage && <img src={displayImage} alt="event banner" />}
              </div>

              <input
                type="file"
                accept="image/png, image/jpeg"
                className="d-none"
                id="customFile"
                name="displayImage"
                onChange={(e) => handleBgUpload(e)}
                style={{ display: "none" }} // Ensure the input is hidden
              />
            </div>

            <div className="row g-2 mt-4">
              <div className="col">
                <div className="form-floating mt-3">
                  <input
                    placeholder="Event title"
                    type="text"
                    className="h-auto form-control"
                    id="floatingInput"
                    name="name"
                    defaultValue={title}
                    onChange={(e) => editEvent("title", e.target.value)}
                  />
                  <label htmlFor="floatingInput">Event Title</label>
                </div>
              </div>

              <div className="col">
                <div className="form-floating mt-3">
                  <select
                    className="form-select"
                    id="floatingSelect"
                    aria-label="select category"
                    name="category"
                    required
                    onChange={(e) => editEvent("category", e.target.value)}
                  >
                    <option value="0">Choose Event Category</option>
                    {cat?.map((c, index) => (
                      <option key={index} value={c._id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Choose Event Category</label>
                </div>
              </div>
            </div>

            <div className="mt-4 form-floating">
              <textarea
                className="form-control"
                placeholder="let your participants know why they should attend"
                id="floatingTextarea2"
                name="description"
                style={{ height: "200px" }}
                defaultValue={desc}
                onChange={(e) => editEvent("description", e.target.value)}
              />
              <label htmlFor="floatingTextarea2">Event Description</label>
            </div>

            <div className="row g-2 mt-4">
              <h5>Event Schedule</h5>
              <p>Schedule date and time of your event.</p>
              <div className="col">
                <div className="form-floating mt-3">
                  <input
                    placeholder="Start Date"
                    type="date"
                    className="h-auto form-control"
                    id="floatingInput"
                    name="startDate"
                    defaultValue={convertISOToMMDDYYYY(startDate)}
                    min={today}
                    onChange={(e) => editEvent("startDate", e.target.value)}
                  />
                  <label htmlFor="floatingInput">Start Date</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mt-3">
                  <input
                    placeholder="End Date"
                    type="date"
                    className="h-auto form-control"
                    id="floatingInput"
                    name="endDate"
                    defaultValue={convertISOToMMDDYYYY(endDate)}
                    min={startDate}
                    onChange={(e) => editEvent("endDate", e.target.value)}
                  />
                  <label htmlFor="floatingInput">End Date</label>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-4">
              <div className="col">
                <div className="form-floating mt-3">
                  <input
                    placeholder="Start Time"
                    type="time"
                    className="h-auto form-control"
                    id="floatingInput"
                    name="startTime"
                    defaultValue={startTime}
                    onChange={(e) => editEvent("startTime", e.target.value)}
                  />
                  <label htmlFor="floatingInput">Start Time</label>
                </div>
              </div>

              <div className="col">
                <div className="form-floating mt-3">
                  <input
                    placeholder="End Time"
                    type="time"
                    className="h-auto form-control"
                    id="floatingInput"
                    name="endtime"
                    defaultValue={endTime}
                    onChange={(e) => editEvent("endTime", e.target.value)}
                  />
                  <label htmlFor="floatingInput">End Time</label>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-4">
              <div className="col">
                <p>Will participants require password to attend your event? </p>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="radio1"
                    defaultChecked={isPassword}
                    onClick={() => editEvent("requirePassword", true)}
                  />
                  <label className="form-check-label" htmlFor="radio1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="radio2"
                    defaultChecked={!isPassword}
                    onClick={() => editEvent("requirePassword", false)}
                  />
                  <label className="form-check-label" htmlFor="radio2">
                    No
                  </label>
                </div>

                {currentEvent?.requirePassword ? (
                  <>
                    <div className="input-group mb-3">
                      <div className="form-floating">
                        <input
                          placeholder="E.g 2000"
                          type={isPassword ? "text" : "password"}
                          className="h-auto form-control"
                          id="password"
                          name="password"
                          defaultValue={password}
                          onChange={(e) =>
                            editEvent("password", e.target.value)
                          }
                        />
                        <label htmlFor="password">Event Password</label>
                      </div>
                      <span
                        onClick={() => setPasswordState(!isPassword)}
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        {isPassword ? (
                          <BsEyeFill
                            style={{ fontSize: "18px", color: "#003399" }}
                          />
                        ) : (
                          <BsEyeSlashFill
                            style={{ fontSize: "18px", color: "#003399" }}
                          />
                        )}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {msg !== "" ? (
              <span style={{ color: "#D42323" }}>
                <strong>{msg}</strong> <br />
                <strong>{isPassword}</strong>
              </span>
            ) : null}
            <div className="row g-2 mt-4">
              <div className="col">
                {disableBtn ? (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="10"
                    color="#003399"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    visible={true}
                  />
                ) : (
                  <button
                    type="submit"
                    onClick={() => saveEvent(currentEvent)}
                    className="btn btn-outline-primary  float-start"
                    disabled={disableBtn}
                  >
                    Save Event
                  </button>
                )}
              </div>
              <div className="col">
                {disableBtn ? (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="10"
                    color="#003399"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    visible={true}
                  />
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary btn-elrio float-end"
                    onClick={() => publishEvent(currentEvent)}
                    disabled={disableBtn}
                  >
                    Publish Event
                  </button>
                )}
              </div>
            </div>
          </Col>
          <Col lg="1" />
        </Row>

        {/* 
                </>
                   } */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EventOffcanvas;
