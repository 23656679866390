import React, { useState } from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";
import { HiCheckCircle } from "react-icons/hi";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import Axios from "axios";
import Config from "../Config.json";

import styles from "./Style.module.css";

const Home = () => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [eventExist, setEventExist] = useState(false);
  const [eventID, setEventID] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [_id, set_id] = useState("");
  const [eventName, setEventName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [btnDisabled, setBtnDisabled] = useState(false);

  // eventId

  const verifyEvent = async () => {
    setBtnDisabled(true);

    return fetch(`${Config.SERVER_URL}/events/key/${eventID}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status === "success") {
          setMsg("Submit details to join event");
          console.log(responseJson.data.event);
          set_id(responseJson.data.event._id);
          setEventName(responseJson.data.event.title);
          if (responseJson.data.event.requirePassword) setIsPassword(true);
          setEventExist(true);
        }
        if (responseJson.status === "error") {
          setMsg("Event does not exist");
        }
        setBtnDisabled(false);
      })
      .catch((error) => {
        console.error(error);
        setBtnDisabled(false);
      });
  };

  const joinEvent = async () => {
    setBtnDisabled(true);
    let details = {};
    if (isPassword) {
      details = {
        name: name,
        password: password,
      };
    }
    if (!isPassword) {
      details = {
        name: name,
      };
    }
    setBtnDisabled(true);
    //   setTimeout(() => {

    //   }, 2000);

    return fetch(
      `${Config.SERVER_URL}/events/${_id}/attenders/anonymous-join`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status === "success") {
          navigate(`/user/Event/${eventName}/${_id}`);
        }
        if (responseJson.status === "error") {
          if (responseJson.error === "Attender with this name already exists") {
            navigate(`/user/Event/${eventName}/${_id}`);
          }
        }
        setMsg(responseJson.errors);
        setBtnDisabled(false);
      })
      .catch((error) => {
        console.error(error);
        setBtnDisabled(false);
      });
  };

  const createEvent = () => {
    const token = sessionStorage.getItem("token");
    // alert(token)

    if (token) {
      // navigate("/user/My-Event");
      window.location.href = "/user/My-Event";
      return;
    }

    window.location.href = "/LogIn";
  };

  return (
    <>
      <Nav />

      <section>
        <div className="wow fadeIn" data-wow-duration="1s">
          <div className={styles.sectionOne}>
            <div className={styles.boxOne}>
              <div className={styles.slide}>
                <h4
                  className={`${styles.headerTitle} ${"wow fadeInUp"}`}
                  data-wow-duration="1s"
                >
                  Hey there! <br className={styles.br} />
                  Welcome, Let's help you create an event memory
                </h4>
                <p
                  className={`${styles.paragraph} ${"wow fadeInUp"}`}
                  data-wow-duration="1.2s"
                >
                  Fero gives you full-fledged tools to host your live events and
                  manage your audience from a single online platform.
                </p>

                <Link
                  to="/Wait-List"
                  className={`${
                    styles.btnRounded
                  } ${"btn btn-primary btn-rounded wow fadeInUp"}`}
                  data-wow-duration="1.8s"
                  type="button"
                >
                  Join Wait List
                </Link>

                {/* <div
                  className={`${styles.action} ${"wow fadeInUp"}`}
                  data-wow-duration="1.5s"
                >
                  <Link
                    onClick={handleShow}
                    className={`${
                      styles.btnRounded
                    } ${"btn btn-primary waves-effect waves-light"}`}
                  >
                    Join Event
                  </Link>

                  <Link
                    onClick={() => createEvent()}
                    to=""
                    className={`${
                      styles.btnRoundedOutline
                    } ${"btn btn-primary waves-effect waves-light"}`}
                  >
                    Create Event
                  </Link>
                </div> */}

                {/* <div className={`${styles.downloadApp} ${"wow fadeInUp"}`}>
                  <div className={styles.dowloadText}>
                    <span>Download our</span>
                    <span>mobile App</span>
                  </div>
                  <div className={styles.icon}>
                    <IoArrowForward />
                  </div>
                  <div className={styles.store}>
                    <img
                      src="./images/android.png"
                      className={`${styles.iosIcon} ${"d-block img-fluid"}`}
                      alt="playstore"
                    />
                    <img
                      src="./images/ios.png"
                      className={`${styles.iosIcon} ${"d-block img-fluid"}`}
                      alt="ios"
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className={styles.boxTwo}>
              <a className="play-btn" href="/">
                &nbsp;
              </a>
              {/* <img src="./images/DJI_0458.JPG" className="d-block w-100 sliderImg" alt="dregding"/> */}
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionTwo}>
        <div
          className={`${styles.whyBox}  ${"wow fadeInUp"}`}
          data-wow-duration="1s"
        >
          <span className={styles.features}>Why Feroevent?</span>
        </div>

        <h3
          className={`${styles.sectionTwoTitle}  ${"wow fadeInUp"}`}
          data-wow-duration="1s"
        >
          Starting your events from the Right Spot!
        </h3>

        <div
          className={`${styles.sectionTwoBox}  ${"wow fadeInUp"}`}
          data-wow-duration="1.1s"
        >
          <div
            className={`${styles.sectionTwoBoxOne}  ${"wow fadeInUp"}`}
            data-wow-duration="1.2s"
          >
            <p className={styles.paragraph}>
              It’s pretty easy! We give you the platform, you connect better
              with your audience! Your invitees can visit your live event as
              they join through your link.
            </p>
          </div>

          <div
            className={`${styles.sectionTwoBoxTwo} ${"wow fadeInUp"}`}
            data-wow-duration="1.2s"
          >
            <img
              src="./images/section1.png"
              className={`${styles.secTwoImg} ${"d-block"}`}
              alt="iphone"
            />
          </div>

          <div
            className={`${styles.sectionTwoBoxThree}  ${"wow fadeInUp"}`}
            data-wow-duration="1.2s"
          >
            <p className={styles.paragraph}>
              With our robust features, you can start streaming your events
              live, share files, chat one-on-one, curate your live events,
              connect with your attendees, and record live events.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.sectionThree}>
        <div className={styles.sectionThreeBoxOne}>
          <img
            src="./images/section2.png"
            className={`${
              styles.secThreeImg
            } ${"d-block img-fluid wow fadeInLeft"}`}
            alt="playstore"
            data-wow-duration="1s"
          />
        </div>

        <div
          className={`${styles.sectionThreeBoxTwo} ${"wow fadeInLeft"}`}
          data-wow-duration="1s"
        >
          {/* <span className={styles.features}>Features</span> */}
          <div className={styles.sectionText}>
            <h3 className={styles.featureTitle}>Event Feeds</h3>
            <p className={styles.featureText}>
              Navigate to your live events using our event feeds and data
              displayed on your chart.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.sectionFour}>
        <div
          className={`${styles.sectionFourBoxOne} ${"wow fadeInRight"}`}
          data-wow-duration="1s"
        >
          {/* <span className={styles.features}>Features</span> */}
          <div className={styles.sectionText}>
            <h3 className={styles.featureTitle}>
              Upload or <br className={styles.br} />
              Download Files
            </h3>
            <p className={styles.featureText}>
              Create customized boards for events and
              <br className={styles.br} /> upload content for download
            </p>
          </div>
        </div>

        <div className={styles.sectionFourBoxTwo}>
          <img
            src="./images/section3.png"
            className={`${
              styles.secFourImg
            } ${"d-block img-fluid wow fadeInRight"}`}
            alt="playstore"
            data-wow-duration="1.2s"
          />
        </div>
      </section>

      <section className={styles.sectionThree}>
        <div className={styles.sectionThreeBoxOne}>
          <img
            src="./images/section4.png"
            className={`${
              styles.secThreeImg
            } ${"d-block img-fluid wow fadeInLeft"}`}
            alt="playstore"
            data-wow-duration="1.2s"
          />
        </div>

        <div
          className={`${styles.sectionThreeBoxTwo} ${"wow fadeInLeft"}`}
          data-wow-duration="1s"
        >
          {/* <span className={styles.features}>Features</span> */}
          <div className={styles.sectionText}>
            <h3 className={styles.featureTitle}>Event Analytics</h3>
            <p className={styles.featureText}>
              Track and analyze interactions between participants through a
              personalized dashboard.
            </p>
            <span className={styles.paragraph}>Event hosts can view the:</span>
            <ul className={styles.liParent}>
              <li>
                <HiCheckCircle style={{ color: "#003399" }} /> Number of likes,
                views and comments.
              </li>
              <li>
                <HiCheckCircle style={{ color: "#003399" }} /> Number of
                attendes for an event.
              </li>
              <li>
                <HiCheckCircle style={{ color: "#003399" }} /> Number of
                followers.
              </li>
              <li>
                <HiCheckCircle style={{ color: "#003399" }} /> People by there
                location using a map
              </li>
              <li>
                <HiCheckCircle style={{ color: "#003399" }} /> Number of those
                who registered for an event.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={styles.sectionFour}>
        <div
          className={`${styles.sectionFourBoxOne} ${"wow fadeInRight"}`}
          data-wow-duration="1s"
        >
          {/* <span className={styles.features}>Features</span> */}
          <div className={styles.sectionText}>
            <h3 className={styles.featureTitle}>
              Recieve payment
              <br className={styles.br} /> for you events
            </h3>
            <p className={styles.featureText}>
              Create customized boards for events and
              <br className={styles.br} /> upload content for download
            </p>
          </div>
        </div>

        <div className={styles.sectionFourBoxTwo}>
          <img
            src="./images/section5.png"
            className={`${
              styles.secFourImg
            } ${"d-block img-fluid wow fadeInRight"}`}
            alt="playstore"
            data-wow-duration="1.2s"
          />
        </div>
      </section>

      <section className={styles.sectionSix}>
        <h3
          className={`${styles.sectionTwoTitle}  ${"wow fadeInUp text-center"}`}
          data-wow-duration="1.2s"
        >
          Perfect for any Use-case scenarios.
        </h3>

        <div className={`${styles.sectionSixBox}`}>
          <div className={`${styles.sectionSixBoxOne}`}>
            <div
              className={`${styles.mar4} ${"col-12 wow fadeInUp"}`}
              data-wow-duration="1.3s"
            >
              <h4 className={styles.subText}>Team Communication</h4>
              <p className={styles.subP}>
                Foster communication and growth with team members by hosting
                live meetings while you host group discussions and workshops
              </p>
            </div>
            <div
              className={`${styles.mar4} ${"col-12 wow fadeInUp"}`}
              data-wow-duration="1.5s"
            >
              <h4 className={styles.subText}>Live Events</h4>
              <p className={styles.subP}>
                Create your live events, seminars, or workshops, from any part
                of the world by broadening your reach and community.
              </p>
            </div>
          </div>

          <div
            className={`${styles.sectionSixBoxTwo} ${"wow fadeInUp"}`}
            data-wow-duration="2.5s"
          >
            <img
              src="./images/section6.png"
              className={`${styles.secSixImg} ${"d-block"}`}
              alt="playstore"
            />
          </div>

          <div className={`${styles.sectionSixBoxThree}`}>
            <div
              className={`${styles.mar4} ${"col-12 wow fadeInUp"}`}
              data-wow-duration="1.3s"
            >
              <h4 className={styles.subText}>Interview Sessions</h4>
              <p className={styles.subP}>
                Perform live interview sessions with your guest and build your
                community and authority with our high-quality a and audio
                quality.
              </p>
            </div>
            <div
              className={`${styles.mar4} ${"col-12 wow fadeInUp"}`}
              data-wow-duration="1.5s"
            >
              <h4 className={styles.subText}>Online Classes</h4>
              <p className={styles.subP}>
                Bring your offline classes to online as an instructor by
                creating a flexible one-to-many interaction with your students
                from a single interface.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionFour} style={{ padding: "60px 10%" }}>
        <div
          className={`${styles.sectionFourBoxOne} ${"wow fadeInRight"}`}
          data-wow-duration="1s"
        >
          <div className={styles.sectionText}>
            <h3 className={styles.featureTitle}>Get our Mobile App</h3>
            <div className={styles.store}>
              <img
                src="./images/android.png"
                className={`${styles.iosIcon} ${"d-block img-fluid"}`}
                alt="playstore"
              />
              <img
                src="./images/ios.png"
                className={`${styles.iosIcon} ${"d-block img-fluid"}`}
                alt="ios"
              />
            </div>
          </div>
        </div>

        <div className={styles.sectionFourBoxTwo}>
          <img
            src="./images/section7.png"
            className={`${
              styles.secFourImg
            } ${"d-block img-fluid wow fadeInRight"}`}
            alt="playstore"
            data-wow-duration="1.2s"
          />
        </div>
      </section>

      <Footer />

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <div className="row py-5">
                <div className="col-12 text-center">
                  {/* <p>The Believers’ Tabernacle </p> */}
                  <h4>JOIN EVENT</h4>
                </div>
                <div className="col-12 text-center">
                  <h5>Fill in the event id to attend your event.</h5>

                  {msg !== "" ? (
                    <span style={{ color: "#D42323" }}>
                      <strong>{msg}</strong> <br />
                    </span>
                  ) : null}
                </div>

                <div className="col-12">
                  <div className="form-floating mt-4 mb-3">
                    <input
                      placeholder="Event ID"
                      type="text"
                      className=" form-control"
                      id="floatingInput"
                      name="eventId"
                      value={eventID}
                      onChange={(e) => setEventID(e.target.value)}
                    />
                    <label for="floatingInput">Event ID</label>
                  </div>
                </div>

                {eventExist && (
                  <>
                    <div className="col-12">
                      <div className="form-floating mt-2 mb-4">
                        <input
                          placeholder="First Name"
                          type="text"
                          className="h-auto form-control"
                          id="floatingInput"
                          name="firstName"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label for="floatingInput">First Name</label>
                      </div>
                    </div>

                    {isPassword && (
                      <div className="col-12 mb-2 mb-4">
                        <div className="form-floating mt-3 mb-4">
                          <input
                            placeholder="Event Password"
                            type="password"
                            className="h-auto form-control"
                            id="floatingInput"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <label for="floatingInput">Event password</label>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="col-12">
                  {btnDisabled !== true ? (
                    <>
                      {eventExist ? (
                        <button
                          onClick={joinEvent}
                          type="button"
                          className="w-100 botton btn btn-primary btn-elrio btn-md"
                        >
                          {" "}
                          Join Event
                        </button>
                      ) : (
                        <button
                          onClick={verifyEvent}
                          type="button"
                          className="w-100 botton btn btn-primary btn-elrio btn-md"
                        >
                          {" "}
                          Confirm Event ID
                        </button>
                      )}
                    </>
                  ) : (
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#003399",
                      }}
                      visible={true}
                    />
                  )}
                </div>
                <div
                  className="col-12 mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <span className="w-100 text-center" onClick={handleClose}>
                    Cancel
                  </span>
                </div>
              </div>
            </div>

            <div className="col-1"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;
