import { format } from "date-fns";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Config from "../Config.json";
// Create the Event Context
const EventContext = createContext();

// Create a custom hook for accessing the context
export const useEvent = () => useContext(EventContext);

// Provider component to wrap around your components
export const EventProvider = ({ children }) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [title, setTitle] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [desc, setDesc] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [cat, setCat] = useState([]);
  const [isPassword, setPasswordState] = useState(false);
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const today = format(new Date(), "yyyy-MM-dd");

  const config = useMemo(
    () => ({
      headers: {
        token: `${sessionStorage.getItem("token")}`,
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }),
    []
  );

  const handleBgUpload = (e) => {
    const file = e.target.files[0];
    setDisplayImage(URL.createObjectURL(file));
  };

  const offCanvasClose = () => {
    setShowOffcanvas(false);
    resetForm(); // Clear form when the offcanvas closes
  };

  const publishEvent = async (currentEvent) => {
    try {
      setDisableBtn(true);
      if (currentEvent?.length === 0) {
        console.error("Invalid field or value");
        return;
      } else {
        const response = await axios.patch(
          `${Config.SERVER_URL}/events/${currentEvent?._id}`,
          { isPublished: true },
          config
        );
        if (response) {
          Swal.fire({
            icon: "success",
            text: "Event Published successfully",
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didClose: () => {
              setTimeout(() => {
                window.location = `/user/details/${currentEvent?.title}/${currentEvent?._id}`;
              }, 100);
            },
          });
        }
      }
    } catch (error) {
      Swal.fire({
        timer: 3000,
        text: `${error?.response?.data?.errors}`,
        toast: true,
        position: "top-right",
        icon: "error",
        showConfirmButton: false,
        timerProgressBar: true,
      });
    } finally {
      setDisableBtn(false);
    }
  };

  const editEvent = async (field, value) => {
    try {
      if (!field || value === undefined || value === null) {
        console.error("Invalid field or value");
        return;
      }
      setCurrentEvent((prevEvent) => {
        const updatedEvent = {
          ...prevEvent,
          [field]: value,
        };
        return updatedEvent;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveEvent = async (event) => {
    try {
      // Clear previous messages and disable the save button
      setMsg("");
      setDisableBtn(true);
      // Validation check for title
      if (title === "") {
        Swal.fire({
          icon: "error",
          text: "Your event must have a title.",
          toast: true,
          timer: 3000,
        });
        setDisableBtn(false);
        return;
      }
      // Create form data for submission, using the updated state values
      const formData = new FormData();
      formData.append("displayImage", displayImage);
      formData.append("title", event?.title);
      formData.append("description", event?.description);
      formData.append("categoryId", event?.categoryId);
      formData.append("startDate", event?.startDate);
      formData.append("endDate", event?.endDate);
      formData.append("startTime", event?.startTime);
      formData.append("endTime", event?.endTime);
      formData.append("isPublished", false);
      formData.append("requirePassword", isPassword);

      if (isPassword) {
        formData.append("password", password);
      }

      let response;
      // Check if it's a new event or an update
      if (currentEvent) {
        // Update existing event
        response = await axios.patch(
          `${Config.SERVER_URL}/events/${event?._id}`,
          formData,
          config
        );
        console.log(response);
      } else {
        // Create a new event
        response = await axios.patch(
          `${Config.SERVER_URL}/events`,
          formData,
          config
        );
      }

      // Handle success response
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: currentEvent
            ? "Event updated successfully"
            : "Event created successfully",
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didClose: () => {
            setTimeout(() => {
              window.location = `/user/My-Event`;
            }, 100);
          },
        });
        resetForm();
      }
    } catch (error) {
      setDisableBtn(false);

      if (error) {
        console.log(error);
        const errorMessage =
          error?.response?.data?.errors || "An error occurred";
        Swal.fire({
          timer: 3000,
          text: errorMessage,
          toast: true,
          position: "top-right",
          icon: "error",
          showConfirmButton: false,
          timerProgressBar: true,
        });
      }
    } finally {
      setDisableBtn(false); // Reset button loading state
    }
  };

  // Set current event when editing an existing one
  const setCurrentEventData = (eventData) => {
    setCurrentEvent(eventData);
    setTitle(eventData.title || "");
    setDisplayImage(eventData.displayImage || "");
    setDesc(eventData.description || "");
    setStartDate(eventData.startDate || "");
    setEndDate(eventData.endDate || "");
    setStartTime(eventData.startTime || "");
    setEndTime(eventData.endTime || "");
    setCategoryId(eventData.categoryId || "");
    setSelectedCat(eventData.category || null);
    setPasswordState(!!eventData.password);
    setPassword(eventData.password || "");
    setShowOffcanvas(true);
  };

  // Reset form when creating a new event or closing the offcanvas
  const resetForm = () => {
    setCurrentEvent(null);
    setTitle("");
    setDisplayImage("");
    setDesc("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setCategoryId(null);
    setSelectedCat(null);
    setPasswordState(false);
    setPassword("");
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(`${Config.SERVER_URL}/categories`, {
          config,
        });
        setCat(data.data.categories);
      } catch (error) {
        console.log(error);
      }
    };
    getCategories();
  }, [config]);

  return (
    <EventContext.Provider
      value={{
        showOffcanvas,
        setShowOffcanvas,
        title,
        setTitle,
        displayImage,
        setDisplayImage,
        desc,
        setDesc,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        categoryId,
        setCategoryId,
        selectedCat,
        setSelectedCat,
        cat,
        setCat,
        isPassword,
        setPasswordState,
        password,
        setPassword,
        msg,
        setMsg,
        loading,
        setLoading,
        handleBgUpload,
        offCanvasClose,
        publishEvent,
        editEvent,
        setCurrentEventData,
        resetForm,
        currentEvent,
        disableBtn,
        setDisableBtn,
        today,
        saveEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
