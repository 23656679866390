import React, { useState, useEffect, useCallback } from "react";
import Config from "../../../../Config.json";
import { ThreeDots } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import { Modal, Row, Col } from "react-bootstrap";
import { BsPencilSquare, BsFillCloudUploadFill } from "react-icons/bs";
import axios from "axios";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { FiEdit2 } from "react-icons/fi";
import { handleDeleteBtn } from "../../../../Utils/utils";
import { useBoardModal } from "../../../../context/BoardContext";

const Board = (props) => {
  const { openBoardModal, updateBoardData } = useBoardModal();
  const [modalShow, setModalShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [typeContent, setTypeContent] = useState("");
  const [content, setContent] = useState("");

  const { data } = props;
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isEdit, setIsEdit] = useState(true);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [createdBoards, setCreatedBoards] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);

  const _id = sessionStorage.getItem("id");

  const getBoards = useCallback(async () => {
    setLoading(true);
    try {
      const board = await axios.get(
        `${Config.SERVER_URL}/events/${data?._id}/boards`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        }
      );
      setBoards(board?.data?.eventBoards);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data?._id]);

  const editOpen = (board) => {
    setId(board._id);
    setName(board.name);
    setType(board.type);
    setTypeContent(board.content);
    setContent(board.content);
    setEditorState(
      EditorState?.createWithContent(
        convertFromRaw(JSON?.parse(board?.content))
      )
    );
    console.log(
      EditorState?.createWithContent(convertFromRaw(JSON.parse(board.content)))
    );
    setModalShow(true);
  };

  const config = {
    headers: {
      token: `${sessionStorage.getItem("token")}`,
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      // 'Content-Type': 'application/json'
    },
  };

  const updateBoard = async () => {
    const data = editorState.getCurrentContent();
    var contentNew = JSON.stringify(convertToRaw(data));

    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    if (type !== "note") formData.append(type, typeContent);
    if (type === "note") formData.append("content", contentNew);

    await axios
      .put(`${Config.SERVER_URL}/events/${_id}/boards/${id}`, formData, config)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: "Board update Successfully",
            position: "top-right",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          setMsg("");
          setLoading(false);
          getBoards();
          setModalShow(false);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        if (response.data.status === "error") {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: response.data.error,
            position: "top-right",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);

        if (err) {
          console.log(err.response.data.error[0]);
          // const {errors} = data;
          setMsg(`Error: ${err.response.data.errors}`);
          setLoading(false);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const createModal = () => {
    setIsEdit(false);
    setModalShow(true);
  };

  const addBoard = async () => {
    if (type === "note") {
      const data = editorState.getCurrentContent();
      var contentNew = JSON.stringify(convertToRaw(data));
    }

    let boardItem = {
      name: name,
      type: type,
      note: contentNew,
      dataContent: typeContent,
    };
    createdBoards.push(boardItem);
  };

  const createBoard = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    if (type !== "note") formData.append(type, typeContent);
    if (type === "note") formData.append("content", "contentNew");

    await axios
      .post(`${Config.SERVER_URL}/events/${id}/boards`, formData, config)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          alert("Board Created Successfully.");
          setMsg("");
          setName("");
          setType("");
          setTypeContent("");
          setContent("");
          setLoading(false);
          setModalShow(false);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        if (response.data.status === "error") {
          setLoading(false);
          alert(response.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          console.log(err.response.data.error[0]);
          // const {errors} = data;
          setMsg(`Error: ${err.response.data.errors}`);
          setLoading(false);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (data?._id) {
      getBoards();
    }
  }, [data?._id, getBoards]);

  return (
    <>
      {loading ? (
        <div>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#003399"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            visible={true}
          />
        </div>
      ) : (
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {boards && boards?.length > 0 ? (
            boards?.map((board, index) => {
              return (
                <div className="accordion-item mb-4" key={board?._id}>
                  <h2 className="accordion-header" id={board._id}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index}`}
                      aria-expanded="false"
                      aria-controls="flush-collapse"
                    >
                      {board?.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={board?._id}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>
                          {board?.type === "image" ? (
                            <img
                              src={board?.content}
                              className="img-fluid d-block "
                              alt="..."
                              style={{
                                width: "100%",
                                height: "50px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                (window.location = `${board?.content}`)
                              }
                            />
                          ) : null}
                          {board?.type === "note" ? (
                            // <Editor
                            //   toolbarHidden
                            //   editorState={EditorState?.createWithContent(
                            //     convertFromRaw(JSON?.parse(board?.content))
                            //   )}
                            //   readOnly={true}
                            // />
                            <p>{board?.content}</p>
                          ) : null}
                          {board?.type === "document" ? (
                            <>
                              <iframe
                                src={`https://docs.google.com/viewerng/viewer?url=${board?.content}&embedded=true `}
                                frameBorder="0"
                                height="300px"
                                width="100%"
                                title={board?.content}
                              ></iframe>
                            </>
                          ) : null}
                          {board?.type === "audio" ? (
                            <>
                              <audio controls>
                                <source src={board.content} type="audio/mpeg" />
                                Your browser does not support the audio tag.
                              </audio>
                            </>
                          ) : null}
                          {board?.type === "video" ? (
                            <video width="100%" height="240" controls>
                              <source src={board.content} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : null}
                        </div>
                        <div
                          className=""
                          style={{ marginBottom: "18px", marginLeft: "10px" }}
                        >
                          <AiFillDelete
                            onClick={() =>
                              handleDeleteBtn(
                                `/events/${data?._id}/boards/${board?._id}`
                              )
                            }
                            className="delPenIcon"
                            style={{ color: "#D42323" }}
                          />
                          <FiEdit2
                            style={{
                              color: "blue",
                              fontSize: "14px !important",
                              cursor: "pointer",
                            }}
                            onClick={() => updateBoardData(board)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <div className="chat">
                <p style={{ fontWeight: "bold", color: "red" }}>
                  No board created yet.
                </p>

                <p>
                  Boards are files, documents, images, & videos that you wish to
                  share with your event attendees to enhance participation.
                </p>

                <p>
                  Start by Creating your board{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => openBoardModal(true)}
                  >
                    here.
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Board;
