import React, {useState, useEffect} from 'react';
import Config from "../../../Config.json";
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from  'react-loader-spinner';
import Axios from "axios";
import Swal from 'sweetalert2';
import MyInterest from './MyInterest';
import Modal from 'react-bootstrap/Modal';
import CreateAndJoin from './CreateAndJoin';

const ProfileComp = () => {
    let navigate = useNavigate();
    const [profilePhotoUrl, setprofilePhotoUrl] = useState('');
    const [profilePhoto, setprofilePhoto] = useState('');
    const [firstName, setFistName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [passwordTypeOld, setPasswordTypeOld] = useState('password');
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isPass, setIsPass] = useState(false);
    const [disabledBtn, setdisabledBtn] = useState(false);
    const [show, setShow] = useState(false);
    const [editStatus, setEditStatus] = useState('profile');
    const [pubEvents, setPubEvents] = useState([])
    const [draftEvents, setDraftEvents] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
      setEditStatus(data)
      setShow(true);
    }

    const config = {
      headers: { 
        'token': `${sessionStorage.getItem('token')}`, 
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`, 
        // 'Content-Type': 'application/json'
      },
    };

    const changeProfileImage = () => {
      document.getElementById('customFile').click();
    }
    const handleImageUpload = (event) =>{
      console.log(event)
      setprofilePhotoUrl(URL.createObjectURL(event.target.files[0]));
      setprofilePhoto(event.target.files[0]);
      updateImg();
    }

    const updateImg = async() => {
      setdisabledBtn(true);

      console.log(profilePhoto)

        const formData = new FormData();
        formData.append('profileImage', profilePhoto);

        return await Axios.patch(`${Config.SERVER_URL}/users/update-profile-image`, formData, config)
        .then((response) => {
          console.log(response.status)
          if(response.status === 200){
            setdisabledBtn(false);
            getProf();
            // alert('Profile Picture updated successfully');
          }
          setdisabledBtn(false);
        })
        .catch(err => {
          console.log(err)
          if(err){
            console.log(err.response.data.error[0]);
          }
          setTimeout(() => {
            
            setdisabledBtn(false);
          }, 1000);
        });

       

    }

    const update =()=>{
      
    
            if(firstName === "" || lastName === "") return Swal.fir({
              text: "Please enter First and Last Name",
              icon: 'error',
              toast:true,
              timer: 3000,
              showConfirmButton:false,
              timerProgressBar: true,
            });
     
    
            setdisabledBtn(true)
      
            return fetch(`${Config.SERVER_URL}/users/update-profile`, {
              method: "patch",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "token":  sessionStorage.getItem('token')  
              },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber:phone
                })
            })
              .then((response) => response.json())
              .then((responseJson) => {
                  console.log(responseJson);
                  if(responseJson.status === "success"){
                    // alert("Profile updated");
                    Swal.fire({
                      icon:'success',
                      text: 'Profile updated successfully',
                      toast:true,
                      timer: 3000,
                      showConfirmButton:false,
                      timerProgressBar: true,
                      position:"top-right"
                    })
                    setdisabledBtn(false);
                    return
                  }
                  if(responseJson.status === "error"){
                    // alert(responseJson.error);
                    Swal.fire({
                      icon: 'error',
                      text: responseJson.error,
                      toast:true,
                      timer: 3000,
                      showConfirmButton:false,
                      timerProgressBar: true,
                      position:"top-right"
                    })
                    setdisabledBtn();
                    return
                  }
              })
              .catch((error) => {
                console.error(error);
                // alert("Somrthing went wrong try again");
                Swal.fire({
                  icon: 'error',
                  text: 'Request failed. Please try again!',
                  toast:true,
                  timer: 3000,
                  showConfirmButton:false,
                  timerProgressBar: true,
                  position:"top-right"
                })
                setdisabledBtn(false);
              });
        
    
    }

    const getProf = async()=> {

        return fetch(`${Config.SERVER_URL}/auth/reload-user`, {
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "token":  sessionStorage.getItem("token")   
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson)
           try {
  
              if (responseJson.status === 'error') {
                setIsPageLoading(false)
                navigate("/LogIn");
                return
              }
  
               setFistName(responseJson.data.user.firstName)
               setLastName(responseJson.data.user.lastName);
               setPhone(responseJson.data.user.phoneNumber);
               setEmail(responseJson.data.user.email);
               setprofilePhotoUrl(responseJson.data.user.profilePhotoUrl);
               setIsPageLoading(false)
           } catch (e) {
                setIsPageLoading(false)
                console.error(e);
           }
  
            })
            .catch((error) => {
              console.error(error);
            });
  
    }

     
    const myPubEvents = async () => {

      const userId = sessionStorage.getItem('userId');
          
    return fetch(`${Config.SERVER_URL}/events?userId=${userId}&isPublished=${true}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token") 
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
            if(responseJson.status === "success") {
              setPubEvents(responseJson.data.events);
              }
              setIsPageLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsPageLoading(false)
          });

   
    }
   
    const myDraftEvents = async () => {

      const userId = sessionStorage.getItem('userId');
          
    return fetch(`${Config.SERVER_URL}/events?userId=${userId}&isPublished=${false}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token") 
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
            if(responseJson.status === "success") {
                setDraftEvents(responseJson.data.events);
              }
              setIsPageLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsPageLoading(false)
          });

  
  }

  const [followers, setfollowers] = useState([]);

  const getFollowers = async() => {

    const userId = await sessionStorage.getItem("userId");


    setIsPageLoading(true);

      return fetch(`${Config.SERVER_URL}/users/${userId}/followers`, {
          method:"get",
          headers:{
              Accept: "application/json",
              "Content-Type": "application/json",
              "token": sessionStorage.getItem("token")  
          }
      })
      .then((response) => response.json())
      .then((responseJson) => {

          if(responseJson.status === "success"){
              setfollowers(responseJson.data.followers);
              setIsPageLoading(false);
          }

      })
      .catch((error) => {
        console.error(error);
        setIsPageLoading(false);
      });




  }


    useEffect(()=> {
        getProf();
        myPubEvents();
        myDraftEvents();
        getFollowers()
    },[])


    const updatePassword = async()=> {

        setdisabledBtn(true)
  
        return fetch(`${Config.SERVER_URL}/auth/change-password`, {
          method: "patch",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem('token')  
          },
            body: JSON.stringify({
                oldPassword: password,
                newPassword: newPassword
            })
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson);
              if(responseJson.status === "success"){
                Swal.fire({
                  text:"Updated Successfully",
                  icon:'success',
                  toast:true,
                  timer: 3000,
                  showConfirmButton:false,
                  timerProgressBar: true,
                });
                setdisabledBtn(false);
                return
              }
              if(responseJson.status === "error"){
                Swal.fire({
                  text:responseJson.error,
                  icon: 'error',
                  toast:true,
                  timer: 3000,
                  showConfirmButton:false,
                  timerProgressBar: true,
                });
                setdisabledBtn();
                return
              }
          })
          .catch((error) => {
            console.error(error);
            alert("Something went wrong try again");
            setdisabledBtn(false);
          });
    }

    const deleteAcc = async() => {
      if (!window.confirm("are you sure you want to delete your account?"))
        return

      const userId = await sessionStorage.getItem('userId');
      setdisabledBtn(true)
  
      return fetch(`${Config.SERVER_URL}/users/${userId}`, {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem('token')  
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("delete reponse ", responseJson);
            if(responseJson.status === "success"){
              Swal.fire({
                text:"Deleted Successfully",
                icon:'success',
                toast:true,
                timer: 3000,
                showConfirmButton:false,
                timerProgressBar: true,
              });
              sessionStorage.clear("token");
              sessionStorage.clear("firstName");
              sessionStorage.clear("lastName");
              sessionStorage.clear("userId");
              window.location.href = "/"
              setdisabledBtn(false);
              return
            }
            if(responseJson.status === "error"){
              Swal.fire({
                text:responseJson.error,
                icon: 'error',
                toast:true,
                timer: 3000,
                showConfirmButton:false,
                timerProgressBar: true,
              });
              setdisabledBtn();
              return
            }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          setdisabledBtn(false);
        });
    }

    return (
        <>

       {isPageLoading === true ?
                <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>
              

             <div className="row">

            {/* here */}
            <CreateAndJoin/>


            <div className="col-lg-4">
                <div className='mt-0 edit_wrap d-flex align-items-center gap-0'>
                  <img src={profilePhotoUrl ?? "../images/user.png"} className="profileImg mb-0" alt="..." style={{cursor: "pointer"}} onClick={() => changeProfileImage()}/> 
                  <input type="file" accept="image/png, image/jpeg" className="form-control d-none" id="customFile" name="profilePhotoUrl" onChange={(e)=> handleImageUpload(e)}/> 
                </div>
                

              {/* <div className="mb-5 mt-3">
                  {disabledBtn ? 
                  <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems:'center'}}>
                      <p>Updating</p>
                </div> 
                  :
                  <i className="fa-solid fa-pen-to-square edit_icon" onClick={() => changeProfileImage()}></i>
                  <button onClick={updateImg} type="button" className=" btn btn-primary btn-elrio btn-sm">Update Image</button>
                  } 
              </div> */}
              
            </div>

            <div className="col-lg-8 profCard">
              
                <div className="d-flex align-items-center gap-5">
                  <h5 className="">{firstName} {" "} {lastName}</h5>
                </div>

                <div className="mt-5 d-flex align-items-end gap-5 text-center">
                    <div className="">
                      <span>{pubEvents?.length}</span>
                      <p>Published</p>
                    </div>
                    <div className="">
                      <span>{draftEvents?.length}</span>
                      <p>Draft</p>
                    </div>
                    <div className="">
                      <span>{followers.length}</span>
                      <p>Followers</p>
                    </div>

                </div>

              </div>



            <div className="col-12 mt-4">
                <MyInterest/>
            </div>

            <div className="profJoinCard mt-4 px-5 py-5">
                    <div className="profHeader">
                      <h5 className=''>Profile</h5>
                    </div>

                    <div className="profDivider">
                      <span className=''>First Name:</span>
                      <span className=''>{firstName}</span>
                    </div>
                    <div className="profDivider">
                      <span className=''>Last Name:</span>
                      <span className=''>{lastName}</span>
                    </div>
                    <div className="profDivider">
                      <span className=''>Email:</span>
                      <span className=''>{email}</span>
                    </div>
                    <div className="profDivider">
                      <span className=''>Phone Number:</span>
                      <span className=''>{phone}</span>
                    </div>
                    <div className="mt-3">
                      <span  onClick={()=> handleShow('password')} className='' style={{cursor:"pointer", color:"rgb(0, 51, 153)" }}>Update password</span>
                    </div>

            <div className="d-flex justify-content-between align-items-center mt-4">
              
              <span  onClick={()=> handleShow('profile')} style={{cursor:"pointer", color:"#003399"}}>Edit Profile</span>

              <span onClick={deleteAcc}style={{cursor:"pointer", color:"red"}}>Delete Account</span>

            </div>



                </div>
                  


                    </div>



    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editStatus === 'profile' ? 'Update Profile' : 'Update Password'}</Modal.Title>

        </Modal.Header>
        
        <Modal.Body>
            
        <div className="col-lg-12">
        {editStatus === 'profile' ? 
              <div className="row">
                    
              <div className="col-12">
                  <div className="form-floating mt-3">
                      <input placeholder="First Name" type="text" className="h-auto form-control" id="floatingInput11" name="firstName"
                        value={firstName} 
                        onChange={(e) => setFistName(e.target.value)}
                      />
                      <label htmlFor="floatingInput1">First Name</label>
                    </div>
              </div>
              <div className="col-12 mt-2">
                  <div className="form-floating mt-3">
                          <input placeholder="Last Name" type="text" className="h-auto form-control" id="floatingInput2" name="LastName"
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <label htmlFor="floatingInput2">Last Name</label>
                      </div>
              </div>
              <div className="col-12 mt-2">
                  <div className="form-floating mt-3">
                          <input placeholder="Phone Number" type="tel" className="h-auto form-control" id="floatingInput3" name="phoneNumber"
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <label htmlFor="floatingInput3">Phone Number</label>
                      </div>
              </div>

              <div className="col-8">
              </div>
              <div className="col-lg-4">
                      <div className="mt-5">
                          {disabledBtn ? 
                          <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems:'center'}}>
                              <p>Updating</p>
                        </div> 
                            
                          :
                          <button onClick={update} type="button" className=" btn btn-primary btn-elrio btn-sm">Update Profile</button>
                          } 
                      </div>
              </div>

              </div>

            :
            
            <div className="row mt-5">
                      <div className="col-lg-12">
                          <div className="row">


                              
                          <div className="col-12">
                              <div className="form-floating mt-3">
                                  <input placeholder="First Name" type={passwordTypeOld} className="h-auto form-control" id="floatingInput4" name="password"
                                  value={password} 
                                  onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <span className='passwordVissibility'>
                                    {passwordTypeOld ==='password'?<i className="fa-solid fa-eye-slash icon" onClick={()=>setPasswordTypeOld('text')}></i> :
                                      <i className="fa-solid fa-eye icon" onClick={()=>setPasswordTypeOld('password')}></i>
                                    }
                                  </span>
                                  <label htmlFor="floatingInput4">Old Password</label>
                                </div>
                          </div>
                          <div className="col-12 mt-2">
                              <div className="form-floating mt-3">
                                      <input placeholder="Last Name" type={passwordType} className="h-auto form-control" id="floatingInput" name="LastName"
                                        value={newPassword} 
                                        onChange={(e) => setNewPassword(e.target.value)}
                                      />
                                      <span className='passwordVissibility'>
                                      {passwordType ==='password'?<i className="fa-solid fa-eye-slash icon"onClick={()=>setPasswordType('text')}></i> :
                                        <i className="fa-solid fa-eye icon" onClick={()=>setPasswordType('password')}></i>
                                      }
                                      </span>
                                      <label htmlFor="floatingInput">New Password</label>
                                      
                                  </div>
                          </div>

                          <div className="col-8">
                          </div>
                          <div className="col-lg-4">
                                  <div className="mt-5">
                                  {disabledBtn ? 
                                    <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems:'center'}}>
                                        <p>Updating</p>
                                    </div> 
                                        
                                    :
                                      <button onClick={updatePassword} type="button" className=" btn btn-primary btn-elrio btn-sm">Update Password</button>
                                      }
                                  </div>
                          </div>



                              </div>
                      </div>
                        

                  </div> 
           } 
           </div>
            
        </Modal.Body>
      </Modal>
            
      
        
            
                      </>
                }

            
        </>
    );
};

export default ProfileComp;