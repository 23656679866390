import React, { createContext, useState, useContext } from "react";

// Create Modal Context
const ModalContext = createContext();

// Create a custom hook for easy access to ModalContext
export const useModal = () => useContext(ModalContext);

// ModalProvider component
export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cameraSetting, setCameraSetting] = useState(false);
  const [startEvent, setStartEvent] = useState(false);

  const openModal = () => setIsOpen(!isOpen);
  const closeModal = () => setIsOpen(!isOpen);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        cameraSetting,
        setCameraSetting,
        startEvent,
        setStartEvent,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
