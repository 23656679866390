import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { FiVideo, FiVideoOff } from "react-icons/fi";
import { useModal } from "../../../../context/modalContext";

const JoinEvent = ({ title }) => {
  const { setCameraSetting, setStartEvent } = useModal();
  const videoRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [stream, setStream] = useState(null);

  const toggleCamera = async () => {
    if (isCameraOn) {
      // Stop the camera
      if (stream) {
        stream?.getTracks()?.forEach((track) => track?.stop());
      }
      setStream(null);
    } else {
      // Start the camera
      try {
        const newStream = await navigator?.mediaDevices?.getUserMedia({
          video: true,
        });
        setStream(newStream);
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } catch (error) {
        console.error("Error accessing the camera", error);
      }
    }
    setIsCameraOn(!isCameraOn);
  };

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks()?.forEach((track) => track?.stop());
      }
    };
  }, [stream]);

  return (
    <>
      <div className="event__wrapper">
        <div className="event__container">
          <div className="event__header">
            <h1>{title}</h1>
          </div>
          <div className="event__body__content">
            <div className="event__video__setup">
              <div className="event__video__wrapper">
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  style={{
                    display: isCameraOn ? "block" : "none",
                  }}
                />
                {!isCameraOn && (
                  <div className="camera__off">
                    <span>
                      <FiVideoOff className="icon" />
                    </span>
                    <h1>Your camera is turned off</h1>
                  </div>
                )}
              </div>
              <div className="event__video__setting">
                {!isCameraOn ? (
                  <FiVideoOff className="icon" />
                ) : (
                  <FiVideo className="icon" />
                )}
                <label className="switch">
                  <input
                    type="checkbox"
                    defaultChecked={isCameraOn}
                    onClick={toggleCamera}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="event__button">
            <button
              onClick={() => setCameraSetting(false)}
              className="btn btn-primary btn-elrio me-4"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCameraSetting(false);
                setStartEvent(true);
              }}
              className="btn btn-primary btn-elrio me-4"
            >
              Join now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinEvent;
