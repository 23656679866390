import React, { createContext, useState, useContext } from "react";

// Create context
const BoardModalContext = createContext();

// Provider component
export const BoardModalProvider = ({ children }) => {
  const [showBoard, setShowBoard] = useState(false);
  const [showEditBoard, setshowEditBoard] = useState(false);
  const [boardData, setBoardData] = useState({});

  const openBoardModal = () => setShowBoard(!showBoard);
  const closeBoardModal = () => setShowBoard(!showBoard);
  const closeEditBoardModal = () => setshowEditBoard(!showEditBoard);

  const openEditBoardModal = () => setshowEditBoard(!showEditBoard);

  const updateBoardData = (newData) => {
    openEditBoardModal(true);
    setBoardData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <BoardModalContext.Provider
      value={{
        showBoard,
        showEditBoard,
        openBoardModal,
        openEditBoardModal,
        closeBoardModal,
        closeEditBoardModal,
        boardData,
        updateBoardData,
      }}
    >
      {children}
    </BoardModalContext.Provider>
  );
};

export const useBoardModal = () => useContext(BoardModalContext);
