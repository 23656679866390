import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { FiCheck } from "react-icons/fi";
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'

const Notes = () => {

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [id, setId] = useState('');
  const [show, setShow] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (n) => {
    setId(n._id)
    setTitle(n.title)
    setContent(n.content)
    setShow(true)
  };

  const [notes, setNotes] = useState([]);

  const getNotes = async() => {
      setPageLoading(true);

      return fetch(`${Config.SERVER_URL}/notes`, {
          method:"get",
          headers:{
              Accept: "application/json",
              "Content-Type": "application/json",
              "token": sessionStorage.getItem("token")  
          }
      })
      .then((response) => response.json())
      .then((responseJson) => {

          if(responseJson.status === "success"){
              setNotes(responseJson.data.notes);
              setPageLoading(false);
          }

      })
      .catch((error) => {
        console.error(error);
        setPageLoading(false);
      });




  }

  useEffect(()=>{
      getNotes();
  },[])


  const updateNote = async() => {
  if(content ===""){
     return
  }
  

  setdisabledBtn(true)
  
  return fetch(`${Config.SERVER_URL}/notes/${id}`, {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "token":  sessionStorage.getItem('token')  
    },
      body: JSON.stringify({
        title: title,
        content: content
      })
  })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log(responseJson);
        if(responseJson.status === "success"){
          alert("Note updated");
          setdisabledBtn(false);
          getNotes();
          setShow(false)
          return
        }
    })
    .catch((error) => {
      console.error(error);
      alert("Somrthing went wrong try again");
      setdisabledBtn(false);
    });
  
}




  const deleteNote = (id) => {


    if (!window.confirm("are you sure you want to delete this user?")) {
      return
    }

    return fetch(`${Config.SERVER_URL}/notes/${id}`, {
        method:"delete",
        headers:{
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")  
        }
    })
    .then((response) => response.json())
    .then((responseJson) => {

        if(responseJson.status === "success"){
            alert("Note deleted");
            getNotes();
        }

    })
    .catch((error) => {
      console.error(error);
      setPageLoading(false);
    });



    

}



    return (
        <>

<div className="row">

  
{pageLoading === true ?
                <>
                  <div style={{height:"30vh"}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>

              {notes && notes.length > 0
                                    ? notes.map((n, index) => {
                                      return <>

                      <div key={index} className="col-lg-12 notebody">
                            <div className="">
                                <div className="card-body">
                                <div onClick={()=> handleShow(n)}>
                                    <h6 className="card-title">{n?.title}</h6>
                                    <p className="card-text">{n ? n?.content.slice(0, 20) : ""}</p>
                                  </div>
                                      <i className="fas fa-trash noteIcon" onClick={()=> deleteNote(n._id)}></i>
                                </div>
                                </div>
                         </div>

                                         </> })
                                : <div style={{minHeight:"30vh", display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", alignItems:'center'}}>
                                <img src={"../images/dataNotFound.png"} className="profileImg mb-0" alt="..."/> 
                                          <h4 style={{marginTop:"20px", fontSize:"16px"}}>No Notes Found</h4>
                                      </div>}

                                      </>
                }

                 </div>


      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">

                          <Modal.Header closeButton>
                            <Modal.Title>Note</Modal.Title>
                          </Modal.Header>
                          
                            <div className="row">
     
                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="Title" type="text" className="h-auto form-control" id="floatingInput" name="firstName"
                                          value={title} 
                                          onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <label for="floatingInput">Title</label>
                                    </div>
                                </div>



                                <div className="col-12"> 
                                <div className="mt-3 form-floating">
                                      <textarea className="form-control" placeholder="Page Content here" id="floatingTextarea2" name="pageContent"  style={{height: "200px"}} 
                                      value={content} 
                                      onChange={(e) => setContent(e.target.value)}
                                    />
                                      <label for="floatingTextarea2">Note Content</label>
                                  </div>
                                </div>


                          {disabledBtn ? <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>updating</span> : 
                              <div onClick={()=> updateNote()} style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"20px"}} disabled={disabledBtn}>
                                  <p style={{backgroundColor:"#003399", padding:"5px 10px", color:"#fff", borderRadius:'5px'}}>Save</p>
                              </div>
                           }

                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
        </Modal.Body>
      </Modal>
            
        </>
    );
};

export default Notes;