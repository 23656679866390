import React from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import styles from "./Style.module.css";
import { HiCheckCircle } from "react-icons/hi";
import GoToTop from "./GoToTop";

const FAQs = () => {
  return (
    <>
      <Nav />

      <section className={styles.bodyContainer}>
        <div id="FAQ">
          <h1
            className={`${styles.digital} ${"wow fadeInUp text-center"}`}
            data-wow-duration="0.8s"
          >
            FAQs
          </h1>

          <div
            className="accordion accordion-flush wow fadeInUp"
            data-wow-duration="1s"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is Elroi and what can it be used for?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A: Elroi is a video conferencing app that allows users to hold
                  meetings and collaborate with team members or participants. It
                  offers features like screen sharing, document sharing, and
                  real-time annotation, making it a powerful tool for
                  presentations, meetings, and other types of remote
                  communication. In addition to these features, Elroi also
                  offers detailed analytics and insights, allowing users to
                  track the success of their meetings and make data-driven
                  decisions about their content. Overall, Elroi is a versatile
                  and effective tool for video and audio conferencing.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Can I share documents during an ELRoi meeting?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A: Yes, one of the distinctive features of Elroi is that it
                  has a feature for event hosts to share documents, pdfs, audio,
                  and other files during a video or audio conference. This
                  allows for a more robust and effective
                  interaction/participation between the hosts and event
                  attendees.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Is the ELRoi app free to use?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A: The app may or may not be free to use, depending on the
                  pricing plan that you choose. Some features may also be
                  available only with a paid subscription.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Can I use the app to do video conferencing on my phone?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A: Yes, the app is available for both iOS and Android devices
                  and can be downloaded from the App Store or Google Play Store.
                  You can host a virtual event using the Elroi app on your
                  phone.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  How do I join a conference on Elroi?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  A: To join a conference, you will need to have the conference
                  link or code provided by the event host. Simply click on the
                  link or enter the code in the app to join the conference.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  8 benefits of using a live streaming tool:
                </button>
              </h2>
              <div
                id="flush-collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingSix"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    1. Increased audience reach: Live streaming allows you to
                    reach a wider audience, as people can watch your stream from
                    anywhere with an internet connection.
                    <br />
                  </p>

                  <p>
                    2. Improved engagement: Live streaming encourages real-time
                    interaction between the presenter and the audience, which
                    can lead to higher levels of engagement and participation.
                    Elroi has a feature for event hosts to share documents,
                    pdfs, audio, and other files during a video or audio
                    conference. This allows for a more robust and effective
                    interaction/participation between the hosts and event
                    attendees. <br />
                  </p>

                  <p>
                    3. Cost-effective: Live streaming can be a cost-effective
                    way to host events and reach a large audience, as it
                    eliminates the need for physical attendance and the
                    associated costs. <br />
                  </p>

                  <p>
                    4. Increased accessibility: Live streaming can make events
                    and content more accessible to people with disabilities or
                    those who are unable to attend in person due to location or
                    other constraints. <br />
                  </p>

                  <p>
                    5. Enhanced credibility: Live streaming adds a level of
                    credibility and professionalism to your content, as it
                    demonstrates that you are actively engaged and committed to
                    your audience. <br />
                  </p>

                  <p>
                    6. Greater flexibility: Video and audio live streaming
                    allows you to adapt and respond to your audience in real
                    time, making it easier to tailor your content to their needs
                    and interests. <br />
                  </p>

                  <p>
                    7. Improved analytics: Elroi offers analytics and insights,
                    allowing you to track the success of your stream and make
                    data-driven decisions about your content. <br />
                  </p>

                  <p>
                    8. Increased reach on social media: Live streaming can help
                    increase the reach of your content on social media
                    platforms, as it generates more engagement and can be easily
                    shared by viewers. Overall, live streaming is a powerful
                    tool for increasing the reach and engagement of your content
                    and can help you connect with your audience in a meaningful
                    and effective way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GoToTop />

      <Footer />
    </>
  );
};

export default FAQs;
