import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModalProvider } from "./context/modalContext";
import { EventProvider } from "./context/EventContext";
import EventOffcanvas from "./Pages/components/EventCanvas";
import { BoardModalProvider } from "./context/BoardContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1031756096926-hdisn9ss4463k5khsva0g1mnp0a3l79h.apps.googleusercontent.com">
      <EventProvider>
        <EventOffcanvas />
        <ModalProvider>
          <BoardModalProvider>
            <App />
          </BoardModalProvider>
        </ModalProvider>
      </EventProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
