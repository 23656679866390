import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Layout from "../Layout";
import EventSide from "./components/EventSide";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { format } from "date-fns";
import Config from "../../../Config.json";
import { ThreeDots } from "react-loader-spinner";
import { BsArrowLeftCircleFill } from "react-icons/bs";
// import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { RWebShare } from "react-web-share";
import Swal from "sweetalert2";
import axios from "axios";

const EventDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [review, setReview] = useState([]);

  const [numbOfFollowers, setnumbOfFollowers] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [host, setHost] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showTwoMore, setShowTwoMore] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [password, setPassword] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTwoClose = () => setShowTwoMore(false);
  const handleTwoShow = () => setShowTwoMore(true);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const regEvent = async () => {
    setBtnDisabled(true);
    console.log("Registering in progress....");
    return fetch(`${Config.SERVER_URL}/events/${data._id}/register`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.data.status === "success") {
          // navigate here
          Swal.fire({
            icon: "success",
            position: "top-right",
            text: "You have successfully registration for this event",
            toast: true,
            timer: 3000,
            showConfirmButton: false,
          });
          setBtnDisabled(false);
          handleClose();
        }
        if (responseJson.status === "error") {
          setBtnDisabled(false);
          alert(responseJson.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const viewStream = () => {
    if (data.requirePassword) return handleTwoShow();
    navigate(`/user/Streaming/${data.title}/${data._id}`, {
      state: { data: data },
    });
  };

  const accessStream = () => {
    navigate(`/user/Streaming/${data.title}/${data._id}`, {
      state: { keys: data },
    });
  };

  const joinPassEvent = async () => {
    setBtnDisabled(true);

    return fetch(`${Config.SERVER_URL}/events/${data._id}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status === "success") {
          navigate(`/user/Streaming/${data.title}/${data._id}`, {
            state: { keys: data },
          });
        }
        setMsg(responseJson.errors);
        setBtnDisabled(false);
      })
      .catch((error) => {
        console.error(error);
        setBtnDisabled(false);
      });
  };

  const displayTitle = data?.title || "";
  const limitedDisplayName =
    displayTitle.length > 25 ? `${displayTitle.slice(0, 25)}...` : displayTitle;

  useEffect(() => {
    const getEvents = async () => {
      try {
        setPageLoading(true);
        const { data } = await axios.get(`${Config.SERVER_URL}/events/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        });
        setData(data?.data?.event);
        setReview(data?.data?.reviews);
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    };
    getEvents();
  }, [id]);

  useEffect(() => {
    const getFollowers = async () => {
      try {
        setPageLoading(true);
        const res = await axios.get(
          `${Config.SERVER_URL}/users/${data.userId}/followers`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        setnumbOfFollowers(res?.data?.data?.followers);
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    };
    getFollowers();
  }, [data.userId]);

  useEffect(() => {
    const getUser = async () => {
      try {
        setPageLoading(true);
        const res = await axios.get(
          `${Config.SERVER_URL}/users/${data.userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        setHost(res?.data?.data?.user);
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }
    };
    getUser();
  }, [data.userId]);

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {pageLoading === true ? (
              <>
                <div>
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="10"
                    color="#003399"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-12">
                  <div className="w-100 titleJoin">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <BsArrowLeftCircleFill
                        onClick={() => navigate(`/user/Events`)}
                        className="arrowIcon"
                        style={{ color: "#003399", fontSize: "25px" }}
                      />
                      <h1 className="title">{limitedDisplayName}</h1>
                    </div>
                    {/* {(
                      format(new Date(), "yyyy-MM-dd") > data.startDate
                        ? format(new Date(data?.endDate), "yyyy-MM-dd")
                        : null
                    ) ? (
                      <button
                        type="button"
                        disabled
                        className="botton btn btn-outline-primary btn-md"
                      >
                        Event ended
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={viewStream}
                        className="botton btn btn-outline-primary btn-md"
                      >
                        Join Event
                      </button>
                    )} */}
                  </div>
                </div>
                <div className="col-lg-12 eventWrapper">
                  <div className="col-lg-8">
                    <div className="eventABg">
                      <img
                        src={data?.displayImage}
                        className="eventImg"
                        alt="..."
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="event-details">
                          <div className="BadagryDate gap-4">
                            <div style={{ display: "flex" }}>
                              <i
                                className="fa-solid fa-calendar-days"
                                style={{ fontSize: "20px" }}
                              ></i>
                              <p
                                style={{ marginLeft: "5px", fontSize: "20px" }}
                              >
                                {data.startDate
                                  ? format(
                                      new Date(data?.startDate),
                                      "dd MMMM yy"
                                    )
                                  : null}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <i
                                className="fa-solid fa-clock"
                                style={{ fontSize: "20px" }}
                              ></i>
                              <p
                                style={{ marginLeft: "5px", fontSize: "20px" }}
                              >
                                {data?.startTime}
                              </p>
                            </div>
                            <RWebShare
                              data={{
                                text: `Use the link to join this event ${data?.title}`,
                                url: `${Config.WEB_URL}/Event/${data?._id}`,
                                title: `${data?.title}`,
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <div
                                style={{ display: "flex", cursor: "pointer" }}
                              >
                                <i
                                  className="fa-solid fa-share"
                                  style={{ fontSize: "20px" }}
                                ></i>
                                <p
                                  className="dateTime"
                                  style={{ fontSize: "20px" }}
                                >
                                  Share this event
                                </p>
                              </div>
                            </RWebShare>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            <p>
                              {data?.description
                                ? showMore
                                  ? data.description
                                  : `${data.description.substring(0, 120)}`
                                : "Loading..."}
                              {data?.description ? (
                                data?.description.length < 220 ? null : (
                                  <>
                                    <span
                                      onClick={() => setShowMore(!showMore)}
                                      className="btn-a"
                                    >
                                      {showMore ? "Show less" : "... Show more"}
                                    </span>
                                  </>
                                )
                              ) : null}{" "}
                            </p>
                          </div>
                          <div className="engagemet">
                            <div className="engagemet-h4">
                              <h4>Events Engagements</h4>
                            </div>
                            <div className="engagementStatsWrapper">
                              <div className="engagementContainer">
                                <div className="engagementStat">
                                  <div>
                                    <i className="fa-solid fa-heart"></i>
                                  </div>
                                  <div className="engagmentStats">
                                    <span>{data?.likesCount}</span>
                                    <span className="">Likes</span>
                                  </div>
                                </div>
                                <div className="engagementStat">
                                  <div>
                                    <i className="fa-solid fa-eye"></i>
                                  </div>
                                  <div className="engagmentStats">
                                    <span>{numbOfFollowers?.length}</span>
                                    <span className="">Followers</span>
                                  </div>
                                </div>
                                <div className="engagementStat">
                                  <div>
                                    <i className="fa-solid fa-comments"></i>
                                  </div>
                                  <div className="engagmentStats">
                                    <span>{review?.length}</span>
                                    <span className="">Review</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr style={{ color: "#003399" }} />

                          <div className="bottom">
                            {(
                              format(new Date(), "yyyy-MM-dd") > data.startDate
                                ? format(new Date(data?.endDate), "yyyy-MM-dd")
                                : null
                            ) ? (
                              <p>Event Closed</p>
                            ) : null}

                            {/* {data?.isLive && (
                              <div className="live-span" style={{}}>
                                <span>live</span>
                              </div>
                            )} */}

                            {(
                              format(new Date(), "yyyy-MM-dd") < data?.startDate
                                ? format(
                                    new Date(data?.startDate),
                                    "yyyy-MM-dd"
                                  )
                                : null
                            ) ? (
                              <button
                                type="button"
                                className=" botton btn btn-primary btn-elrio btn-md"
                                onClick={handleShow}
                              >
                                Register for Event
                              </button>
                            ) : null}

                            {(
                              format(new Date(), "yyyy-MM-dd") > data?.startDate
                                ? format(new Date(data?.endDate), "yyyy-MM-dd")
                                : null
                            ) ? (
                              <button
                                type="button"
                                onClick={accessStream}
                                className="botton btn btn-outline-primary btn-md"
                              >
                                Access Event
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!pageLoading && (
                    <div className="col-lg-4">
                      <h1
                        className=""
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Recommended Events
                      </h1>
                      <EventSide catId={data?.categoryId} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal show={showTwoMore} onHide={handleTwoClose}>
        <Modal.Body>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <div className="row py-5">
                <div className="col-12 text-center">
                  {/* <p>The Believers’ Tabernacle </p> */}
                  <h4>JOIN EVENT</h4>
                </div>
                <div className="col-12 text-center">
                  <h5>This event requires password</h5>

                  {msg !== "" ? (
                    <span style={{ color: "#D42323" }}>
                      <strong>{msg}</strong> <br />
                    </span>
                  ) : null}
                </div>

                <div className="col-12 mb-2 mb-4">
                  <div className="form-floating mt-3 mb-4">
                    <input
                      placeholder="Event Password"
                      type="password"
                      className="h-auto form-control"
                      id="floatingInput"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label for="floatingInput">Event password</label>
                  </div>
                </div>

                <div className="col-12">
                  {btnDisabled !== true ? (
                    <>
                      <button
                        onClick={joinPassEvent}
                        type="button"
                        className="w-100 botton btn btn-primary btn-elrio btn-md"
                      >
                        {" "}
                        Join Event
                      </button>
                    </>
                  ) : (
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="10"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#003399",
                      }}
                      visible={true}
                    />
                  )}
                </div>
                <div
                  className="col-12 mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <span className="w-100 text-center" onClick={handleTwoClose}>
                    Cancel
                  </span>
                </div>
              </div>
            </div>

            <div className="col-1"></div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <div className="row py-5">
                <div className="col-12 text-center">
                  {/* <p>The Believers’ Tabernacle </p> */}
                  <h4>{data?.title}</h4>
                  {/* <p className='time'>{data.startDate ? format(new Date(data?.startDate), 'dd-MMM-yyyy')}   {data?.startTime} </p> */}
                </div>
                <div className="col-12 text-center">
                  <h5>Event Registration</h5>
                </div>

                <div className="col-12">
                  <div className="form-floating mt-3">
                    <input
                      placeholder="First Name"
                      type="text"
                      className="h-auto form-control"
                      id="floatingInput"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setfirstName(e.target.value)}
                    />
                    <label for="floatingInput">First Name</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating mt-3">
                    <input
                      placeholder="Last Name"
                      type="text"
                      className="h-auto form-control"
                      id="floatingInput"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                    />
                    <label for="floatingInput">Last Name</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mt-3 form-floating">
                    <input
                      placeholder="Email"
                      type="email"
                      className="h-auto form-control"
                      id="floatingInput"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label for="floatingInput">Email Address</label>
                  </div>
                </div>

                <div className="col-12">
                  <p className="time mt-4">
                    The information you provide when registering will be shared
                    with the host in accordance with ElRoi{" "}
                    <a href="">Terms and Privacy Policy</a>.
                  </p>
                </div>
                <div className="col-12">
                  {btnDisabled !== true ? (
                    <button
                      type="button"
                      className="w-100 botton btn btn-primary btn-elrio btn-md"
                      onClick={regEvent}
                    >
                      {" "}
                      Confirm Registration
                    </button>
                  ) : (
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="10"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#003399",
                      }}
                      visible={true}
                    />
                  )}
                </div>
                <div
                  className="col-12 mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <span className="w-100 text-center" onClick={handleClose}>
                    Cancel
                  </span>
                </div>
              </div>
            </div>

            <div className="col-1"></div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default EventDetails;
